import React from "react";
import {graphql} from "gatsby";
import parse from "html-react-parser";
import {Asset, Link} from "../../../../plugins/gatsby-plugin-atollon";
import StructuredText from "../custom-structured-text-renderer";

const ThankYou = ({title, link, richContent}) => {

  return (
    <section className='thank-you'>
      <div className='thank-you__inner'>
        <div className='thank-you__title'>
          {title && <h1>{parse(title)}</h1>}
          {richContent && <StructuredText data={richContent} />}
          <div className='thank-you__button'>
            {link.length > 0 &&
              <Link className='btn btn--border-white' to={link[0].link[0].externalLink ? link[0].link[0].externalLink : link[0].link[0].internalLink.permalink}>
                {link[0].title}
              </Link>}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ThankYou

export const query = graphql`
fragment ThankYouProps on DatoCmsThankYou {
  ... on DatoCmsThankYou {
    id
    title
    richContent {
      value
    }
    link {
      ...LinkWithTitleProps
    }
  }
}
`;