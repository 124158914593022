import React from "react";
import {graphql} from "gatsby";
import FormComponent from "../../form";
import parse from "html-react-parser";
import {Asset, Link} from "../../../../plugins/gatsby-plugin-atollon";

const Contact = ({form, title}) => {

  return (
    <section className='contact'>
      <div className='contact__inner'>
        {title && <h2>{parse(title)}</h2>}

        <div className='contact__wrapper'>
          <div className='contact__content'>
            <div className='contact__content--item'>
              <Link to={`tel:0297554222`}>02 9755 4222</Link>
              <p>3/50 Governor Macquarie Drive Chipping Norton, NSW 2170</p>
            </div>
            <div className='contact__content--item'>
            <Link to={`tel:0297554222`}>02 9755 4222</Link>
              <p>1/50 Australis Avenue Wattle Grove, NSW 2173</p>
            </div>
          </div>

          <div className='contact__form'>
            <FormComponent form={form}/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact

export const query = graphql`
fragment ContactProps on DatoCmsContact {
  ... on DatoCmsContact {
    id
    title
    form {
      ...FormProps
    }
  }
}
`;