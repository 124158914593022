import React from "react";
import {Link, Asset} from "../../../../plugins/gatsby-plugin-atollon";
import {graphql} from "gatsby";
import parse from "html-react-parser";
import IMG from '../../../assets/images/agent-form.jpg'
import { gsap } from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const HighlightCta = ({title, image, subtitle, link}) => {

  return (
    <section className='highlight-cta'>
      <div className="highlight-cta__inner">
        <div className='highlight-cta__wrapper' data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>
          {image ? <Asset className="highlight-cta__bg" asset={image}/> :
            <div className='highlight-cta__bg'>
              <img src={IMG} alt="highlight"/>
            </div>
          }

          <div className="highlight-cta__content">
            {subtitle && <span>{parse(subtitle)}</span>}
            {title && <h2>{parse(title)}</h2>}
          </div>

          <div className="highlight-cta__button">
            {link?.length > 0 &&
              <Link className='btn btn--border-white' to={link[0].link[0].externalLink ? link[0].link[0].externalLink : link[0].link[0].internalLink.permalink}>
                {link[0].title}
              </Link>}
          </div>
        </div>
      </div>
    </section>);
};

export default HighlightCta;

export const query = graphql`
  fragment HighlightCtaProps on DatoCmsHighlightCta {
    ... on DatoCmsHighlightCta {
      id
      title
      subtitle
      image {
        ...InternalAssetProps
      }
      link {
        ...LinkWithTitleProps
      }
    }
  }
`;
