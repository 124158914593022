import React from 'react'
import { MediaItem } from '../../../plugins/gatsby-plugin-atollon'
import { Link } from '../../../plugins/gatsby-plugin-atollon'
import AddToCalendarHOC from 'react-add-to-calendar-hoc'
import moment from 'moment'
import Stats from "../property/stats";

const ATCDropdown = (args) => (
  <ul className='atc-dropdown'>
    { args.children.map((link, i) => (
      <li key={ i }>{ link }</li>
    )) }
  </ul>
)

const ATCWrapper = (args) => (
  <Link onClick={ args.onClick } className='atc-item' href='/'>
    <span dangerouslySetInnerHTML={ { __html: args.children } }/>
  </Link>
)

const Auction = (props) => {

  const renderAuction = () => {
    let title = props.address;
    if (!props.auctionDateTime) {
      return null;
    }
    const AddToCalendarDropdown = AddToCalendarHOC(ATCWrapper, ATCDropdown);
    let duration = 1;
    let auctionEvent = {
      description: `Auction @ ${title}`, duration, endDatetime: moment(props.auctionDateTime).add(1, "hours").format("YYYYMMDDTHHmmss"), location: title, startDatetime: moment(props.auctionDateTime).format("YYYYMMDDTHHmmss"), title: `${title} - Property Auction`,
    };
    return (<AddToCalendarDropdown
      buttonText={`
              <p>${moment(props.auctionDateTime).format("h:mma")}</p> 
              ${moment(props.auctionDateTime).format("dddd Do, MMMM")}
              <p><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V10M13 1V5M5 1V5M1 9H17M13 17H19M16 14V20" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> Add to Calendar</p>`}
      event={auctionEvent}
    />);
  };

  const renderInspection = (inspection) => {
    let title = props.address
    const AddToCalendarDropdown = AddToCalendarHOC(ATCWrapper, ATCDropdown)
    let duration = 1
    let inspectionEvent = {
      description: `Inspection @ ${ title }`,
      duration,
      endDatetime: moment(inspection.startDateTimeUtc).add(0.5, "hours").format('YYYYMMDDTHHmmss'),
      location: title,
      startDatetime: moment(inspection.startDateTimeUtc).format('YYYYMMDDTHHmmss'),
      title: `${ title } - Property Inspection`,
    }
    return (
        <AddToCalendarDropdown 
          buttonText={`
            <p>${inspection.startTime} - ${inspection.endTime}</p> 
            ${inspection.date}
            <p><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.5 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V10M13 1V5M5 1V5M1 9H17M13 17H19M16 14V20" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> Add to Calendar</p>`}
          event={ inspectionEvent }
        />
    )
  }

  const stableDateUnix = props.isInspection ? new Date(props.inspectionCutoffDate).getTime() : 0;

  return (
    <div className='card card--upcoming' data-sal='slide-up' data-sal-duration='1000'>
      <div className='card--upcoming__wrapper'>
        <Link to={props.permalink} className='card--upcoming__image'>
          {props.images?.length > 0 && props.images[0].primary?.length > 0 && (
            <MediaItem item={props.images[0].primary}/>)}
        </Link>
        <Link to={props.permalink} className='card--upcoming__content'>
          <div className='card__title'>
            {!props.addressComponents && props.address && <h4>{props.address}</h4>}
            {props.addressComponents?.length > 0 && <h4>
              {props.addressComponents[0]?.unit && `${props.addressComponents[0]?.unit} / `}
              {props.addressComponents[0]?.streetNumber} {props.addressComponents[0]?.street + ''}<br/>
              {props.addressComponents[0]?.city}
            </h4>}
          </div>

          <div className='card__stats'>
            <Stats {...props} />
          </div>
        </Link>
        <div className='card--upcoming-auction'>
          <div className='card--upcoming-auction__auction'>
            <div className='card--upcoming-auction__rows'>
              {props.isInspection && Array.isArray(props.inspections) && (
                <div className='card--upcoming-auction__row'>
                  <h3>Inspection</h3>
                  {props.inspections.filter(x => {
                    return new Date(x.startDateTimeUtc).getTime() > stableDateUnix
                  }).sort((a, b) => {
                    return new Date(a.startDateTimeUtc).getTime() - new Date(b.startDateTimeUtc).getTime()
                  }).slice(0, 2).map((x => (<p><div>{renderInspection(x)}</div></p>)))}
                </div>)}
              {!props.isInspection && props.auctionDateTime && (<div className='card--upcoming-auction__row'>
                  <h3>Auction</h3>
                  {props.auctionDateTime && <p>{renderAuction()}</p>}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='card--upcoming__button'>
        {props.permalink && <Link className='btn btn--border-black-white' to={props.permalink}>View Listing</Link>}
      </div>
    </div>
  )
}

export default Auction