import { useStaticQuery, graphql } from "gatsby"

const useSiteOptionQuery = () => {
  const { datoCmsSiteOptionPage, latestSale, latestSold, latestLease, latestProject } = useStaticQuery(graphql`
    query SiteOption {
      latestSale: allDatoCmsProperty(sort: {marketingLiveDate: DESC}, limit: 1, filter: {for: {eq: "sale"}}) {
        nodes {
          address
          addressComponents {
            buildingName
            city
            country
            id
            level
            region
            regionShort
            street
            streetNumber
            unit
            postCode
          }
          thumbnail {
            ...InternalAssetProps
          }
          permalink
        }
      }
      latestProject: allDatoCmsProject(sort: {marketingLiveDate: DESC}, limit: 1) {
        nodes {
          address
          addressComponents {
            buildingName
            city
            country
            id
            level
            region
            regionShort
            street
            streetNumber
            unit
            postCode
          }
          thumbnail {
            ...InternalAssetProps
          }
          permalink
        }
      }
      latestSold: allDatoCmsProperty(sort: {soldDate: DESC}, limit: 1, filter: {for: {eq: "sold"}}) {
        nodes {
          address
          addressComponents {
            buildingName
            city
            country
            id
            level
            region
            regionShort
            street
            streetNumber
            unit
            postCode
          }
          thumbnail {
            ...InternalAssetProps
          }
          permalink
        }
      }
      latestLease: allDatoCmsProperty(sort: {marketingLiveDate: DESC}, limit: 1, filter: {for: {eq: "lease"}}) {
        nodes {
          address
          addressComponents {
            buildingName
            city
            country
            id
            level
            region
            regionShort
            street
            streetNumber
            unit
            postCode
          }
          thumbnail {
            ...InternalAssetProps
          }
          permalink
        }
      }
      datoCmsSiteOptionPage {
        office {
          phoneNumber
          location {
            value
          }
        }
        featureAgent {
          email
          name
          jobTitle
          permalink
          phone
          profileImage {
            ...InternalAssetProps
          }
        }
        divisions {
          title
          richContent {
            value
          }
          image {
            ...InternalAssetProps
          }
        }
      }
    }
`)

  return { datoCmsSiteOptionPage, latestSale, latestSold, latestLease, latestProject }
}

export default useSiteOptionQuery