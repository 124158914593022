import React from "react";
import { Asset, Link } from "../../../../plugins/gatsby-plugin-atollon";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { gsap } from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const PrideCta = ({title, image, subtitle, link}) => {

  React.useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: `.pride-cta`,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
      },
    });

    tl.fromTo(
      `.pride-cta__bg`,
      { y: 0 },
      { y: 400, ease: 'none' }
    );

    return () => {
      tl.kill()
    }
  }, []);

  return (
    <section className="pride-cta">
      <div className="pride-cta__asset">
        {image && <Asset className="pride-cta__bg" asset={image}/>}
      </div>
      <div className="pride-cta__inner">
         {subtitle && <span data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>{parse(subtitle)}</span>}
         {title && <h2 data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>{parse(title)}</h2>}
         {link?.length > 0 &&
            <Link className='btn btn--border-white' to={link[0].link[0].externalLink ? link[0].link[0].externalLink : link[0].link[0].internalLink.permalink}>
              {link[0].title}
            </Link>}   
      </div>
    </section>
  );
};

export default PrideCta;

export const query = graphql`
  fragment PrideCtaProps on DatoCmsPrideCta {
    ... on DatoCmsPrideCta {
      id
      title
      subtitle
      image {
        ...InternalAssetProps
      }
      link {
        ...LinkWithTitleProps
      }
    }
  }
`;