import React from "react";

const Stats = (props) => {
  return (
    <div className='property-stats'>
    <ul>
      {props.beds > 0 && <li>{props.beds} Bed | </li>}
      {props.baths > 0 &&<li>{props.baths} Bath | </li>}
      {props.allCarSpaces > 0 && <li>{props.allCarSpaces} Car</li>}
      { props.landArea && props.market === 'Commercial' && <li>{ props.landArea.replace('m2', 'm²') }</li> }
    </ul>
  </div>
  )
}

export default Stats