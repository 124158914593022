import React from "react";
import {Asset, Link} from "../../plugins/gatsby-plugin-atollon";
import {Logo, Back} from "./icons";
import {useStaticQuery} from "gatsby";
import PropertyCard from "./cards/property";
import useSiteOptionQuery from "../hooks/use-site-option-query";
import StructuredText from "./structured-text/custom-structured-text-renderer";
import AgentCard from "./cards/agent";
import useLatestPropertyQuery from "../hooks/use-latest-property-query";

const Header = ({pageContext}) => {
  const latestProperty = useLatestPropertyQuery()
  const [offCanvas, setOffCanvas] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)
  const [sticky, setSticky] = React.useState(false)
  const [sellMenu, setSellMenu] = React.useState(false)
  const [buyMenu, setBuyMenu] = React.useState(false)
  const [leaseMenu, setLeaseMenu] = React.useState(false)
  const [pMMenu, setPMMenu] = React.useState(false)
  const [teamMenu, setTeamMenu] = React.useState(false)
  const [dropdownMenu, setDropdownMenu] = React.useState({
    dropdownActive: false,
    menu: [],
    initial: false,
  })
  const [offCanvasMenu, setOffCanvasMenu] = React.useState({
    subActive: false,
    menu: [],
    initial: false,
  })
  const [direction, setDirection] = React.useState('down')
  const siteOption = useSiteOptionQuery()

  const openBuyMenu = () => {
    setBuyMenu(true)
  }

  const closeBuyMenu = () => {
    setBuyMenu(false)
  }
  
  const openSellMenu = () => {
    setSellMenu(true)
  }

  const closeSellMenu = () => {
    setSellMenu(false)
  }

  const openLeaseMenu = () => {
    setLeaseMenu(true)
  }

  const openPMMenu = () => {
    setPMMenu(true)
  }

  const closeLeaseMenu = () => {
    setLeaseMenu(false)
  }

  const closePMMenu = () => {
    setPMMenu(false)
  }

  const openTeamMenu = () => {
    setTeamMenu(true)
  }

  const closeTeamMenu = () => {
    setTeamMenu(false)
  }

  let props = {
    onClick: () => {
      setOffCanvas(false)
      setSellMenu(false)
      setTeamMenu(false)
      setBuyMenu(false)
      setPMMenu(false)
      setLeaseMenu(false)
    },
    activeClassName: 'active',
  }

  const closeMenu = () => {
    setOffCanvasMenu({
      subActive: false,
      menu: [],
      initial: false,
    })
    setDropdownMenu({
      dropdownActive: false,
      menu: [],
      initial: false,
    })
    setOffCanvas(false)
  }

  const dropdownToggle = (key) => {
    let {dropdownActive, menu, initial} = dropdownMenu
    dropdownActive = false
    initial = false

    if (dropdownMenu.menu[key]) {
      setDropdownMenu({
        dropdownActive: false,
        menu: [],
        initial: false,
      })
      return
    }

    if (menu[key]) {
      menu[key] = true
    } else {
      for (let i = 0; i < menu.length; i++) {
        menu[i] = false
      }
      menu[key] = true
    }

    dropdownActive = true
    setDropdownMenu({initial, menu, dropdownActive})
  }

  let isScrolling
  let prevScroll = 0

  const onScroll = (e) => {

    let scr, hhScrolled

    const curScroll = window.scrollY || document.scrollTop
    const stickyHeader = document?.querySelector(".sticky-header")
    scr = curScroll > 1
    const dir = curScroll > prevScroll ? "down" : "up"

    // Make sure they scroll more than 5px
    if (Math.abs(prevScroll - curScroll) <= 5) return

    if (stickyHeader) {
      if (direction === "down") {
        setSticky(true)
        stickyHeader.classList.add("active")
      } else {
        setSticky(false)
        stickyHeader.classList.remove("active")
      }
    } else {
      setSticky(false)
    }

    prevScroll = curScroll

    requestAnimationFrame(() => {
      setScrolled(scr)
      setDirection(dir)
    })
  }

  const scrollDetect = () => {
    window.clearTimeout(isScrolling)
    isScrolling = setTimeout(onScroll, 0)
  }

  React.useEffect(() => {
    window.addEventListener("scroll", scrollDetect, false)
    return () => {
      window.removeEventListener("scroll", scrollDetect, false)
    }
  }, [])

  let headerClass = "header"
  if (scrolled || sellMenu || buyMenu || leaseMenu || teamMenu || pMMenu || offCanvas) headerClass += " header--scrolled"
  if (offCanvas) headerClass += " header--off-canvas"
  if (sticky) headerClass += " header--sticky"
  if (dropdownMenu.dropdownActive) headerClass += " header--dropdown-active"
  if ( pageContext?.lightHeader ) headerClass += ` header--light`
  if ( direction === 'down' && scrolled && !sellMenu && !buyMenu && !leaseMenu && !teamMenu && !pMMenu && !offCanvas ) headerClass += ' header--up'

  return (
    <>
      <header className={headerClass}>
        <div className='header__inner'>
          <div className='header__logo'>
            <Link {...props} to='/'><Logo/></Link>
          </div>

          <div className='header__nav'>
            <ul>
              <li onMouseEnter={openBuyMenu} onMouseLeave={closeBuyMenu}>
                <Link {...props} to='/buy/'>Buy</Link>
                <div className={`header__sell ${ buyMenu ? 'active' : ''}`}>
                  <div className='header__sell--inner'>
                    <ul>
                      <li><Link to='/buy' {...props}>View All Properties</Link></li>
                      <li><Link to='/upcoming-inspections' {...props}>Upcoming Inspections</Link></li>
                      <li><Link to='/upcoming-auctions' {...props}>Upcoming Auctions</Link></li>
                      <li><Link to='/appraisal' {...props}>Get an Appraisal</Link></li>
                      <li><Link to='/finance' {...props}>Finance</Link></li>
                    </ul>
                    <button className='header__button' onClick={closeBuyMenu}>Buy <Back/></button>
                    {
                     siteOption?.latestSale?.nodes?.length > 0 &&
                      <div className='header__sell--listing'>
                        <h3>Just Listed</h3>
                        <div className='header__sell--items' {...props}>
                          {
                            siteOption?.latestSale?.nodes.map((el, i) => (
                              <PropertyCard key={i} {...el} />
                            ))
                          }
                        </div>
                    </div>
                    }
                  </div>
                </div>
              </li>
              <li onMouseEnter={openSellMenu} onMouseLeave={closeSellMenu}>
                <Link {...props} to='/sold/'>Sell</Link>
                <div className={`header__sell ${ sellMenu ? 'active' : ''}`}>
                  <div className='header__sell--inner'>
                    <ul>
                      <li><Link to='/sold' {...props}>Recently Sold</Link></li>
                      <li><Link to='/finance/' {...props}>Finance</Link></li>
                      <li><Link to='/appraisal' {...props}>Get an Appraisal</Link></li>
                    </ul>
                    <button className='header__button' onClick={closeSellMenu}>Sell <Back/></button>
                    {
                     siteOption?.latestSold?.nodes?.length > 0 &&
                      <div className='header__sell--listing'>
                        <h3>Recently Sold</h3>
                        <div className='header__sell--items' {...props}>
                          {
                            siteOption?.latestSold?.nodes.map((el, i) => (
                              <PropertyCard key={i} {...el} />
                            ))
                          }
                        </div>
                    </div>
                    }
                  </div>
                </div>
              </li>
              <li onMouseEnter={openLeaseMenu} onMouseLeave={closeLeaseMenu}>
                <Link {...props} to='/lease/'>Lease</Link>
                <div className={`header__sell ${ leaseMenu ? 'active' : ''}`}>
                  <div className='header__sell--inner'>
                    <ul>
                      <li><Link to='/lease/' {...props}>View All Properties</Link></li>
                      <li><Link to='/upcoming-inspections-lease/' {...props}>Upcoming Inspections</Link></li>
                      <li><Link to='/appraisal/' {...props}>Get an Appraisal</Link></li>
                    </ul>
                    <button className='header__button' onClick={closeLeaseMenu}>Lease <Back/></button>
                    {
                     siteOption?.latestLease?.nodes?.length > 0 &&
                      <div className='header__sell--listing'>
                        <h3>Recent Lease</h3>
                        <div className='header__sell--items' {...props}>
                          {
                            siteOption?.latestLease?.nodes.map((el, i) => (
                              <PropertyCard key={i} {...el} />
                            ))
                          }
                        </div>
                    </div>
                    }
                  </div>
                </div>
              </li>
              <li onMouseEnter={openPMMenu} onMouseLeave={closePMMenu}>
                <Link {...props} to='/development-sites/'>Property Marketing</Link>
                <div className={`header__sell ${ pMMenu ? 'active' : ''}`}>
                  <div className='header__sell--inner'>
                    <ul>
                      <li><Link to='/projects/' {...props}>Projects</Link></li>
                      <li><Link to='/projects/?for%5Bin%5D%5B%5D=past-projects/' {...props}>Past Projects</Link></li>
                      <li><Link to='/development-sites/' {...props}>Development Sites</Link></li>
                      <li><Link to='/project-team/' {...props}>Team</Link></li>
                    </ul>
                    <button className='header__button' onClick={closePMMenu}>Property Marketing <Back/></button>
                    {
                     siteOption?.latestSale?.nodes?.length > 0 &&
                      <div className='header__sell--listing'>
                        <h3>Latest Project</h3>
                        <div className='header__sell--items' {...props}>
                          {
                            siteOption?.latestProject?.nodes.map((el, i) => (
                              <PropertyCard key={i} {...el} />
                            ))
                          }
                        </div>
                    </div>
                    }
                  </div>
                </div>
              </li>
              <li onMouseEnter={openTeamMenu} onMouseLeave={closeTeamMenu}>
                <Link {...props} to='/team'>Team</Link>
                <div className={`header__team ${teamMenu ? 'active' : ''}`}>
                  <div className='header__team--inner'>
                    {
                     siteOption?.datoCmsSiteOptionPage?.divisions?.length > 0 && <div className='header__team--items' {...props}>
                      {siteOption?.datoCmsSiteOptionPage?.divisions?.map((el, i) => (<Link to={`/team?division=${i === 0 ? 'sales' : 'rental'}`} key={i} className='header__team--item' {...props}>
                          {el.title && <h3>{el.title}</h3>}
                          {el.image && <Asset asset={el.image}/>}
                          {el.richContent && <StructuredText data={el.richContent}/>}
                        </Link>
                      ))}
                    </div>
                    }

                    <button className='header__button' onClick={closeTeamMenu}>Team <Back/></button>
                  </div>
                </div>
              </li>

              <li>
                <Link {...props} to='/about/'>About</Link>
              </li>

              <li><Link {...props} to='/contact'>Contact</Link></li>
            </ul>
          </div>

          <div className='header__hamburger' onClick={() => setOffCanvas(!offCanvas)}>
            <span className='lines'></span>
          </div>
        </div>
      </header>
      <div className={`off-canvas ${offCanvas ? 'active' : ''}`}>
        <div className='off-canvas__inner'>
          <div className='off-canvas__nav'>
            <ul>
              <li><button onClick={openBuyMenu}>Buy</button></li>
              <li><button onClick={openSellMenu}>Sell</button></li>
              <li><button onClick={openLeaseMenu}>Lease</button></li>
              <li><button onClick={openTeamMenu}>Team</button></li>
              <li><button onClick={openPMMenu}>Property Marketing</button></li>
              <li><Link to='/about' {...props}>About</Link></li>
              <li><Link to='/contact' {...props}>Contact</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header