import React, {useRef} from 'react'
import { MediaItem, Link, Asset} from '../../../plugins/gatsby-plugin-atollon'
import { New, Sold } from "../icons";

const PropertyCard = (props) => {

  //if props empty object return null
  if (!props) return null
  // if marketing live date within last 14 days show new tag
  const marketingLiveDate = new Date(props.marketingLiveDate)
  const today = new Date()
  const timeDiff = Math.abs(today.getTime() - marketingLiveDate.getTime())
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

  return (
    <Link to={props.permalink} className={`card card--property ${props.for === 'sold' ? 'card--property__sold' : ''}`} data-sal='slide-up' data-sal-duration='1000'>
      <div className='card--property__wrapper'>
        <div className='card--property__image'>
          {props.images?.length > 0 && props.images[0].primary?.length > 0 ? (
            <Link to={props.permalink}>
              <MediaItem item={props.images[0].primary}/>
            </Link>
          ) : <Asset asset={props.thumbnail} />}
          {props.for === 'sale' && diffDays <= 7 && <span className='card__tag'><New /></span>}
          {props.for === 'sold' && <span className='card__tag'><Sold /></span>}
        </div>
        <div className='card--property__content'>
          <div className='card--property__title'>
            {!props.addressComponents && props.address && <h4>{props.address}</h4>}
            {props.addressComponents?.length > 0 && <h4>
              {props.addressComponents[0]?.unit && `${props.addressComponents[0]?.unit} / `}
              {props.addressComponents[0]?.streetNumber} {props.addressComponents[0]?.street + ''},<br/>
              {props.addressComponents[0]?.city} {props.addressComponents[0]?.postCode}
            </h4>}

            <div className='card--property__stats'>
              <ul>
                {props.beds > 0 && <li>{props.beds} <span>Bed</span> | </li>}
                {props.baths > 0 && <li>{props.baths} <span>Bath</span> | </li>}
                {props.allCarSpaces > 0 && <li>{props.allCarSpaces} <span>Car</span></li>}
              </ul>
            </div>
          </div>
          <div className='card--property__price'>
            <p>{props.price}</p>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default PropertyCard