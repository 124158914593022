import {FilterModel} from "../types";
import { bedBathCarOptions, propertyTypeOptions, priceMin, priceMax, rentPriceMin, rentPriceMax } from "../constants"
import {navigate} from "gatsby";
import {buildSearchQuery} from "../search";

const property: FilterModel =  {
    initialState: ({ componentProps: { initialPropertyType }, queryTypeArgs, location }) => {
        let args = {}
        if (location.pathname.includes("/buy")) {
            args = {
                type: 'property',
                fields: {
                    for: {
                        eq: 'sale'
                    },
                    market: {
                        eq: 'residential'
                    },
                },
                sort: { label: 'Recently Listed', sort: { type: 'scalar', fieldName: 'marketingLiveDate', direction: 'desc' }},
                perPage: 27
            }
        } else if (location.pathname.includes("/development-sites")) {
            args = {
                type: 'property',
                fields: {
                    for: {
                        eq: 'sale'
                    },
                    developmentSite: {
                        eq: true
                    },
                },
                sort: { label: 'Recently Listed', sort: { type: 'scalar', fieldName: 'marketingLiveDate', direction: 'desc' }},
                perPage: 27
            }
        } else if (location.pathname.includes("/sold")) {
            args = {
                type: 'property',
                fields: {
                    for: {
                        eq: 'sold'
                    },
                    market: {
                        eq: 'residential'
                    },
                },
                sort: { label: 'Recently Sold', sort: { type: 'scalar', fieldName: 'soldDate', direction: 'desc' }},
                perPage: 27
            }
        } else if (location.pathname.includes("/lease")) {
            args = {
                type: 'property',
                fields: {
                    for: {
                        eq: 'lease'
                    },
                    market: {
                        eq: 'residential'
                    },
                },
                sort: { label: 'Recently Listed', sort: { type: 'scalar', fieldName: 'marketingLiveDate', direction: 'desc' }},
                perPage: 27
            }
        } else if (location.pathname.includes("/leased")) {
            args = {
                type: 'property',
                fields: {
                    for: {
                        eq: 'leased'
                    },
                    market: {
                        eq: 'residential'
                    },
                },
                sort: { label: 'Recently Listed', sort: { type: 'scalar', fieldName: 'marketingLiveDate', direction: 'desc' }},
                perPage: 27
            }
        } else if (queryTypeArgs?.type === "project") {
            args = {
                type: 'project',
                fields: {
                    for: {
                        in: ['construction-underway', 'now-selling']
                    },
                },
                perPage: 27,
            }
        } else {
            args = {
                type: 'property',
                fields: {
                    for: {
                        eq: 'sale'
                    },
                    market: {
                        eq: 'residential'
                    }
                },
                sort: { label: 'Recently Listed', sort: { type: 'scalar', fieldName: 'marketingLiveDate', direction: 'desc' }},
                perPage: 27
            }
        }

        return args
    },
    placeholder: () => ({
        desktop: 'Type a postcode, suburb or keyword',
        mobile: 'Type a postcode, suburb or keyword'
    }),
    primaryFilter: () => ({
        name: 'category',
        label: 'Category',
        addFilter: ({ props, currentState, location, option, ...rest }) => {
            if (option.value === 'lease') {
                props.setType('property');
                props.removeFieldFilter('propertyType');
                props.setFieldFilter({ for: { eq: 'lease' } })
                if (!location.pathname.includes("search")) {
                    navigate(`/for-lease/${buildSearchQuery(props.getVirtualResult())}`)
                }
            } else if (option.value === 'leased') {
                props.setType('property');
                props.setFieldFilter({ for: { eq: 'leased' }})
                if (!location.pathname.includes("search")) {
                    navigate(`/sold/${buildSearchQuery(props.getVirtualResult())}`)
                }
            } else if (option.value === 'sold') {
                props.setType('property');
                props.setFieldFilter({ for: { eq: 'sold' }})
                if (!location.pathname.includes("search")) {
                    navigate(`/sold/${buildSearchQuery(props.getVirtualResult())}`)
                }
            } else if (option.value === 'buy' || option.value === 'sale') {
                props.setType('property')
                props.removeFieldFilter('propertyType');
                props.setFieldFilter({ for: { eq: 'sale' }})
                if (!location.pathname.includes("search")) {
                    navigate(`/for-sale/${buildSearchQuery(props.getVirtualResult())}`)
                }
            }
        },
        isActive: ({ currentState, option }) => {
            const currentStateForIn = currentState?.fields?.for?.eq || '';
            if (option.value === 'lease') {
                return currentStateForIn === 'lease';
            } else if (option.value === 'leased') {
                return currentStateForIn === 'leased';
            } else if (option.value === 'sold') {
                return currentStateForIn === 'sold';
            } else {
                return currentStateForIn === 'sale';
            }
        },
        options: ({ currentState }) => {
            return [
                {label: "Buy", value: 'sale'},
                {label: "Lease", value: 'lease'},
                {label: "Sell", value: 'sold'}
            ]
        },
    }),
    addSearchText: ({ props, value }) => {
        props.setFieldFilter({ address: { matches: { pattern: value, caseSensitive: false } }});
    },
    removeSearchText: ({ props }) => {
        props.removeFieldFilter('address');
    },
    getSearchText: ({ currentState }) => {
        return currentState?.fields?.address?.matches?.pattern
    },
    secondaryFilters: ({ currentState }) => {
        if (currentState.type === 'project') {
            return [
                {
                    name: 'property-type',
                    label: 'Project Type',
                    addFilter: ({ props, option }) => {
                        if (option.value === '') {
                            props.setFieldFilter({ for: { in: ['construction-underway', 'now-selling'] } });
                        } else {
                            props.setFieldFilter({ for: { in: [option.value] } });
                        }
                    },
                    options: () => [
                        {label: "Current Projects", value: ''},
                        {label: "Now Selling", value: 'now-selling'},
                        {label: "Construction Underway", value: 'construction-underway'},
                        {label: "Past Projects", value: 'past-projects'},
                    ],
                    isActive: ({ currentState, option }) => {

                        if (option.value === '') {
                            if (currentState?.fields?.for?.in) {
                                return currentState.fields.for.in.length > 1
                            }
                        } else {
                            if (currentState?.fields?.for?.in) {
                                return currentState.fields.for.in.length === 1 && currentState?.fields?.for?.in?.[0] === option.value
                            }
                        }
                    },
                },
                {
                    name: 'price-min',
                    label: 'Min',
                    addFilter: ({ props, option }) => {
                        props.combineFieldFilter({ priceSearch: { gte: option.value }});
                    },
                    options: () => priceMin,
                    isActive: ({ currentState, option }) => {
                        return currentState?.fields?.priceSearch?.gte === option.value
                    },
                },
                {
                    name: 'price-max',
                    label: 'Max',
                    addFilter: ({ props, option }) => {
                        props.combineFieldFilter({ priceSearch: { lte: option.value }});
                    },
                    options: () => priceMax,
                    isActive: ({ currentState, option }) => {
                        return currentState?.fields?.priceSearch?.lte === option.value
                    },
                }
            ];
        }
        return [
            {
                name: 'property-type',
                label: 'Property Type',
                addFilter: ({ props, option }) => {
                    if (!option.value) {
                        props.removeFieldFilter('propertyType');
                    } else {
                        props.setFieldFilter({ propertyType: { eq: option.value }});
                    }
                },
                options: () => propertyTypeOptions,
                isActive: ({ currentState, option }) => {
                    if (option.value === '') {
                        return !currentState?.fields?.propertyType?.eq && !currentState?.fields?.propertyType?.in
                    } else {
                        return currentState?.fields?.propertyType?.eq === option.value
                    }
                },
            },
            {
                name: 'price-min',
                label: 'Min',
                addFilter: ({ props, option }) => {
                    props.combineFieldFilter({ priceSearch: { gte: Number(option.value) }});
                },
                options: () => currentState.fields?.for?.eq === 'lease' ? rentPriceMin : priceMin,
                isActive: ({ currentState, option }) => {
                    if (option.value === '' || Number(option.value) === 0) {
                        return !currentState?.fields?.priceSearch?.gte
                    } else {
                        return currentState?.fields?.priceSearch?.gte === option.value
                    }
                },
            },
            {
                name: 'price-max',
                label: 'Max',
                addFilter: ({ props, option }) => {
                    props.combineFieldFilter({ priceSearch: { lte: Number(option.value) }});
                },
                options: () => currentState.fields?.for?.eq === 'lease' ? rentPriceMax : priceMax,
                isActive: ({ currentState, option }) => {
                    if (option.value === '' || Number(option.value) === 0) {
                        return !currentState?.fields?.priceSearch?.lte
                    } else {
                        return currentState?.fields?.priceSearch?.lte === option.value
                    }
                },
            },
            {
                name: 'bed',
                label: 'Bed',
                addFilter: ({ props, option }) => {
                    if (!option.value) {
                        props.removeFieldFilter('beds')
                        return;
                    }
                    if ( Number(option.value) === 5) {
                        props.setFieldFilter({ beds: { gte: Number(option.value) } });
                    } else {
                        props.setFieldFilter({ beds: { eq: Number(option.value) } });
                    }
                },
                options: () => bedBathCarOptions,
                isActive: ({ currentState, option }) => {
                    if (option.value === '') {
                        return !currentState?.fields?.beds?.eq
                    } else {
                        return currentState?.fields?.beds?.eq === option.value
                    }
                },
            },
            {
                name: 'bath',
                label: 'Bath',
                addFilter: ({ props, option }) => {
                    if (!option.value) {
                        props.removeFieldFilter('baths')
                        return;
                    }
                    if ( Number(option.value) === 4) {
                        props.setFieldFilter({ baths: { gte: Number(option.value) } });
                    } else {
                        props.setFieldFilter({ baths: { eq: Number(option.value) } });
                    }
                },
                options: () => bedBathCarOptions,
                isActive: ({ currentState, option }) => {
                    if (option.value === '') {
                        return !currentState?.fields?.baths?.eq
                    } else {
                        return currentState?.fields?.baths?.eq === option.value
                    }
                },
            },
            {
                name: 'car',
                label: 'Car',
                addFilter: ({ props, option }) => {
                    if (Number(option.value) === 0 || !option.value) {
                        props.removeFieldFilter('allCarSpaces')
                        return;
                    }
                    if ( Number(option.value) === 5) {
                        props.setFieldFilter({ allCarSpaces: { gte: Number(option.value) } });
                    } else {
                        props.setFieldFilter({ allCarSpaces: { eq: Number(option.value) } });
                    }
                },
                options: () => bedBathCarOptions,
                isActive: ({ currentState, option }) => {
                    if (option.value === '') {
                        return !currentState?.fields?.allCarSpaces?.eq
                    } else {
                        return currentState?.fields?.allCarSpaces?.eq === option.value
                    }
                },
            },
        ];
    },
    sorts: ({ currentState, componentProps, stableDate, referenceType }) => {
        if (currentState.fields?.for?.eq === 'sale' && currentState.type === 'property' && referenceType === 'auction') {
            return [
                { label: 'Price High to Low', sort: { type: 'scalar', fieldName: 'priceSearch', direction: 'desc' }},
                { label: 'Price Low to High', sort: { type: 'scalar', fieldName: 'priceSearch', direction: 'asc' }},
                { label: 'Earliest Auction', sort: { type: 'scalar', fieldName: 'auctionDateTime', direction: 'asc' }},
                { label: 'Latest Auction', sort: { type: 'scalar', fieldName: 'auctionDateTime', direction: 'desc' }},
            ]
        }

        if (currentState.fields?.for?.eq === 'sale' && currentState.type === 'property' && referenceType === 'inspection') {
            return [
                { label: 'Price High to Low', sort: { type: 'scalar', fieldName: 'priceSearch', direction: 'desc' }},
                { label: 'Price Low to High', sort: { type: 'scalar', fieldName: 'priceSearch', direction: 'asc' }},
                { label: 'Earliest Inspection',
                    sort: {
                        type: 'referencing',
                        referenceType: 'inspection',
                        fieldName: 'startDateTimeUtc',
                        direction: 'asc'
                    },
                    referenceFields: { startDateTimeUtc: { gte: stableDate } },
                },
                { label: 'Latest Inspection',
                    sort: {
                        type: 'referencing',
                        referenceType: 'inspection',
                        fieldName: 'startDateTimeUtc',
                        direction: 'desc'
                    },
                    referenceFields: { startDateTimeUtc: { gte: stableDate } },
                },
            ]
        }

        if (currentState.fields?.for?.eq === 'sale' && currentState.type === 'property') {
            return [
                { label: 'Recently Listed', sort: { type: 'scalar', fieldName: 'marketingLiveDate', direction: 'desc' }},
                { label: 'Price High to Low', sort: { type: 'scalar', fieldName: 'priceSearch', direction: 'desc' }},
                { label: 'Price Low to High', sort: { type: 'scalar', fieldName: 'priceSearch', direction: 'asc' }},
                { label: 'Earliest Inspection',
                    sort: {
                        type: 'referencing',
                        referenceType: 'inspection',
                        fieldName: 'startDateTimeUtc',
                        direction: 'asc'
                    },
                    referenceFields: { startDateTimeUtc: { gte: stableDate } },
                },
                { label: 'Earliest Auction', sort: { type: 'scalar', fieldName: 'auctionDateTime', direction: 'asc' }},
                { label: 'Date - Newest', sort: { type: 'scalar', fieldName: 'marketingLiveDate', direction: 'desc' }},
                { label: 'Date - Oldest', sort: { type: 'scalar', fieldName: 'marketingLiveDate', direction: 'asc' }},
            ]
        }

        if (currentState.fields?.for?.eq === 'sold' && currentState.type === 'property') {
            return [
                { label: 'Recently Sold', sort: { type: 'scalar', fieldName: 'soldDate', direction: 'desc' }},
                { label: 'Price High to Low', sort: { type: 'scalar', fieldName: 'priceSearch', direction: 'desc' }},
                { label: 'Price Low to High', sort: { type: 'scalar', fieldName: 'priceSearch', direction: 'asc' }},
            ]
        }

        if (currentState.fields?.for?.eq === 'lease' && currentState.type === 'property') {
            return [
                { label: 'Recently Listed', sort: { type: 'scalar', fieldName: 'marketingLiveDate', direction: 'desc' }},
                { label: 'Price High to Low', sort: { type: 'scalar', fieldName: 'priceSearch', direction: 'desc' }},
                { label: 'Price Low to High', sort: { type: 'scalar', fieldName: 'priceSearch', direction: 'asc' }},
                { label: 'Earliest Inspection',
                    sort: {
                        type: 'referencing',
                        referenceType: 'inspection',
                        fieldName: 'startDateTimeUtc',
                        direction: 'asc'
                    },
                    referenceFields: { startDateTimeUtc: { gte: stableDate } },
                },
            ]
        }

        if (currentState?.type === 'project') {
            return [
                { label: 'Sort A to Z', sort: { type: 'scalar', fieldName: 'title', direction: 'asc' }},
                { label: 'Recently Listed', sort: { type: 'scalar', fieldName: 'marketingLiveDate', direction: 'desc' }},
                { label: 'Price High', sort: { type: 'scalar', fieldName: 'priceSearch', direction: 'desc' }},
                { label: 'Price Low', sort: { type: 'scalar', fieldName: 'priceSearch', direction: 'asc' }}
            ]
        }

        return []
    },
}

export default property