/* eslint-disable react/jsx-props-no-spreading, react/prop-types, import/no-cycle */
import React from 'react';
import EmptyBlock from './blocks/empty-block';
import LandingBanner from "./blocks/landing-banner";
import AboutBanner from "./blocks/about-banner";
import PrideCta from './blocks/pride-cta';
import ExpertCta from './blocks/expert-cta';
import HighlightCta from './blocks/highlight-cta';
import FeaturedProperty from './blocks/featured-property';
import FeatureProperty from './blocks/feature-property';
import Service from './blocks/service';
import AgentQuote from './blocks/agent-quote';
import Expander from "./blocks/expander";
import Timeline from "./blocks/timeline";
import TeamArchive from "./blocks/team-archive";
import FormBlock from "./blocks/form-block";
import Contact from "./blocks/contact";
import ThankYou from "./blocks/thank-you";
import TitleContentExpander from "./blocks/title-content-expander";
import TitleContentButtonImage from './blocks/title-content-button-image';
import TitleGallery from './blocks/title-gallery';
import PropertyArchive from './blocks/property-archive';
import Upcoming from './blocks/upcoming';
import UpcomingInspection from './blocks/upcoming-inspection';
import { useDebug, DebugPill } from "../../../plugins/gatsby-plugin-atollon";

/*
* Context for retaining render depth
* */
export const BlockRendererContext = React.createContext(-1);

export const RenderBlock = ({ __typename, ...props }) => {
    switch (__typename.replace('DatoCms', '')) {
        case 'LandingBanner':
            return <LandingBanner {...props} />;
        case 'AboutBanner':
            return <AboutBanner {...props} />;
        case 'PrideCta':
            return <PrideCta {...props} />;
        case 'ExpertCta':
            return <ExpertCta {...props} />;
        case 'HighlightCta':
            return <HighlightCta {...props} />;
        case 'TitleContentButtonImage':
            return <TitleContentButtonImage {...props} />;
        case 'FeaturedProperty':
            return <FeaturedProperty {...props} />;
        case 'FeatureProperty':
            return <FeatureProperty {...props} />;
        case 'PropertyArchive':
            return <PropertyArchive {...props} />;
        case 'Service':
            return <Service {...props} />;
        case 'AgentQuote':
            return <AgentQuote {...props} />;
        case 'Expander':
            return <Expander {...props} />;
        case 'Timeline':
            return <Timeline {...props} />;
        case 'TeamArchive':
            return <TeamArchive {...props} />;
        case 'FormBlock':
            return <FormBlock {...props} />;
        case 'Contact':
            return <Contact {...props} />;
        case 'ThankYou':
            return <ThankYou {...props} />;
        case 'TitleContentExpander':
            return <TitleContentExpander {...props} />;
        case 'TitleGallery':
            return <TitleGallery {...props} />;
        case 'Upcoming':
            return <Upcoming {...props} />;
        case 'UpcomingInspection':
            return <UpcomingInspection {...props} />;
        default:
            return <EmptyBlock>{__typename}</EmptyBlock>;
    }
};

const BlockRenderer = ({ data, className }) => {
    const { showBlockNames } = useDebug();
    const existingRenderContextDepth = React.useContext(BlockRendererContext);

    return (
        <BlockRendererContext.Provider value={existingRenderContextDepth + 1}>
            {data && data.map((block) => {
                const { __typename: blockType } = block;

                if (!blockType) {
                    throw new Error("You must source __typename on every blocks field.")
                }

                return (
                    <>
                        {showBlockNames && (
                            <DebugPill style={{
                                position: 'absolute',
                            }}
                            >
                                {blockType}
                            </DebugPill>
                        )}
                        <RenderBlock
                            key={block.id}
                            className={className}
                            {...block}
                        />
                    </>
                );
            })}
        </BlockRendererContext.Provider>
    );
};

export default BlockRenderer;
