import React from 'react'
import { Asset, Link } from '../../../../plugins/gatsby-plugin-atollon'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import Stats from "../../property/stats";

const FeaturedProperty = ({saleProperty, leaseProperty, title}) => {
  const [type, setType] = React.useState(saleProperty)

  const renderProperty = (el) => {
    if (el && el?.length <= 0) return null
    return (
      <div className={`featured-property__item ${type.for === el?.for ? 'active' : ''}`} >
        {el?.thumbnail && <Link to={el?.permalink} className='featured-property__item--image'><Asset asset={el?.thumbnail}/></Link>}
        <div className='featured-property__item--content'>
          <Link to={el?.permalink} className='featured-property__item--title'>
            {el?.address && <h4>{el.address}</h4>}
            <Stats {...el} />
          </Link>

          <Link to={el?.permalink} className='featured-property__item--price'>
            {el?.price && <p>{el?.price}</p>}
          </Link>

          <div className='featured-property__item--button'>
            {el?.permalink && <Link className='btn btn--border-black-white' to={el?.permalink}>View Listing</Link>}
          </div>
        </div>
      </div>
    )
  }

  return (
  <section className='featured-property'>
      <div className='featured-property__inner'>
        <div className='featured-property__wrapper' data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>
          {title && <h4>{parse(title)} <Link to={type.for === 'sale' || type.for === 'sold' ? '/buy/' : '/lease/'}>View all</Link></h4>}

          <form className='featured-property__filter'>
            {type?.heading && <h2>{parse(type.heading)}</h2>}
            { saleProperty?.address && 
              <div className='form__radio'>
                <input onChange={(e) => setType(saleProperty)} checked={type.for === 'sale' || type.for === 'sold'} name="prefer" id='for-sale' type="radio"/>
                <label htmlFor='for-sale'>For Sale</label>
              </div>
            }
            { leaseProperty?.address &&
              <div className='form__radio'>
                <input onChange={(e) => setType(leaseProperty)} checked={type.for === 'lease' || type.for === 'leased'} name="prefer" id='for-lease' type="radio"/>
                <label htmlFor='for-lease'>For Lease</label>
              </div>
            }
          </form>

          <div className='featured-property__items'>
            { saleProperty?.address && renderProperty(saleProperty)}
            { leaseProperty?.address && renderProperty(leaseProperty)}
          </div>
          </div>
        </div>
    </section>
  )
}

export default FeaturedProperty

export const query = graphql`
fragment FeaturedPropertyProps on DatoCmsFeaturedProperty {
  ... on DatoCmsFeaturedProperty {
    id
    title
    saleProperty {
      allBathrooms
      allCarSpaces
      baths
      beds
      carports
      address
      addressComponents {
        streetNumber
        street
        city
        unit
      }
      for
      heading
      images {
        primary {
          ...MediaItemProps
        }
      }
      thumbnail {
        ...InternalAssetProps
      }
      price
      permalink
    }
    leaseProperty {
      allBathrooms
      allCarSpaces
      baths
      beds
      carports
      address
      addressComponents {
        streetNumber
        street
        city
        unit
      }
      for
      heading
      images {
        primary {
          ...MediaItemProps
        }
      }
      thumbnail {
        ...InternalAssetProps
      }
      price
      permalink
    }
  }
}
`;