import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import StructuredText from "../custom-structured-text-renderer";
import { Asset, Link } from "../../../../plugins/gatsby-plugin-atollon";

const TitleContentButtonImage = ({title, richContent, image, link}) => {

  return (
    <section className='tcbi'>
      <div className="tcbi__inner">
        <div className='tcbi__wrapper' data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>
          <div className="tcbi__content">
            {title && <h2>{parse(title)}</h2>}
            {richContent.value && <div>
              <StructuredText data={richContent}/></div>}
            {link?.length > 0 &&
              <Link className='btn btn--border-black' to={link[0].link[0].externalLink ? link[0].link[0].externalLink : link[0].link[0].internalLink.permalink}>
                {link[0].title}
              </Link>}
          </div>
          <div className="tcbi__image">
            {image &&
              <div><Asset asset={image}/></div>}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TitleContentButtonImage

export const query = graphql`
  fragment TitleContentButtonImageProps on DatoCmsTitleContentButtonImage {
    ... on DatoCmsTitleContentButtonImage {
      id
      title
      richContent {
        value
      }
      image {
        ...InternalAssetProps
      }
      link {
        ...LinkWithTitleProps
      }
    }
  }
`;