import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import { Asset, Link } from "../../../../plugins/gatsby-plugin-atollon";
import FormComponent from "../../form";
import IMG from '../../../assets/images/agent-form.jpg'

const FormBlock = ({title, image, form}) => {

  return (
    <section className='form-block'>
      <div className="form-block__inner">
        <div className='form-block__wrapper'>
          <div className="form-block__content">
            {title && <h2 data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>{parse(title)}</h2>}
            {form && <FormComponent form={form} />}
          </div>
          <div className="form-block__image">
            {image ?
              <div data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'><Asset asset={image}/></div> :
              <div data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>
                <div className='gatsby-image-wrapper'>
                  <img src={IMG} alt="form"/>
                </div>
              </div>}
          </div>
        </div>
      </div>
    </section>
  )
}

export default FormBlock

export const query = graphql`
  fragment FormBlockProps on DatoCmsFormBlock {
  ... on DatoCmsFormBlock {
    id
    title
    form {
      ...FormProps
    }
    image {
      ...InternalAssetProps
    }
  }
}
`;