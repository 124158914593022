import React from 'react'
import { graphql } from 'gatsby'
import SearchUpcomingComponent from "../../search/search-upcoming";

const UpcomingInspection = ({ title, propertyType }) => {
  return (
      <SearchUpcomingComponent
        searchType={'property'}
        defaultHeading={title}
        forType={propertyType || 'sale'}
        referenceType={'inspection'}
      />
  )
}

export default UpcomingInspection

export const query = graphql`
fragment UpcomingInspectionProps on DatoCmsUpcomingInspection {
  ... on DatoCmsUpcomingInspection {
    id
    title
    propertyType
  }
}
`