import React, { useEffect, Component } from "react";
import { MediaItem } from "../../../../plugins/gatsby-plugin-atollon";
import { graphql } from "gatsby";
import parse from "html-react-parser";

const TitleGallery = ({subtitle, title, gallery}) => {

  return (
    <section className={`range-slider-item`}>
      <div className="range-slider-item__inner">
        {subtitle && <p data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">{parse(subtitle)}</p>}
        {title && <h2 data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">{parse(title)}</h2>}
        <div className="range-slider-item__items" >
          { gallery?.length > 0 && gallery.map((el, i) => (
            <div key={i} className="range-slider-item__item">
              {el && <MediaItem item={el}/>}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default TitleGallery

export const query = graphql`
fragment TitleGalleryProps on DatoCmsTitleGallery {
  ... on DatoCmsTitleGallery {
    id
    subtitle
    title
    gallery {
      ...MediaItemProps
    }
  }
}
`