import React from 'react'
import { graphql } from 'gatsby'
import SearchComponent from "../../search/search";

const PropertyArchive = ({ title, propertyType, market }) => {
  return (
    <SearchComponent
      searchType={propertyType === 'project' ? 'project' : 'property'}
      defaultHeading={title}
      market={market ? market : 'residential'}
      forType={propertyType ? propertyType : 'sale'}
    />
  )
}

export default PropertyArchive

export const query = graphql`
fragment PropertyArchiveProps on DatoCmsPropertyArchive {
  ... on DatoCmsPropertyArchive {
    title
    propertyType
    market
  }
}
`
