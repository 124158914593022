import React from 'react'
import { Asset, Link } from '../../../../plugins/gatsby-plugin-atollon'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import StructuredText from "../custom-structured-text-renderer";
import {Quote} from "../../icons";

const AgentQuote = ({quote, name, jobTitle, richContent, agent}) => {
  return (
    <section className='agent-quote'>
        <div className='agent-quote__inner'>
          <div className='agent-quote__image' data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>
            {agent?.profileImage && <Asset asset={agent?.profileImage} />}
          </div>

          <div className='agent-quote__content' data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>
            {quote && <Quote/>}
            {quote && <h2>{parse(quote)}</h2>}
            {agent?.profileImage && <Asset asset={agent?.profileImage} />}
            {(name || agent?.name )&& <h3>{parse(name || agent?.name)}</h3>}
            {(jobTitle ||agent?.jobTitle) && <span>{parse(jobTitle || agent?.jobTitle)}</span>}
            {richContent && <StructuredText data={richContent} />}
            {agent?.permalink && <Link to={agent?.permalink} className='btn btn--border-black-white'>View Profile</Link>}
          </div>
        </div>
    </section>
  )
}

export default AgentQuote

export const query = graphql`
fragment AgentQuoteProps on DatoCmsAgentQuote {
  ... on DatoCmsAgentQuote {
    id
    quote
    name
    jobTitle
    richContent {
      value
    }
    agent {
      title
      permalink
      biography {
        value
      }
      name
      phone
      profileImage {
        ...InternalAssetProps
      }
      jobTitle
    }
  }
}
`;