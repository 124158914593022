import React, { useEffect } from 'react';
import { graphql } from "gatsby";
import AgentCard from "../../cards/agent";
import parse from "html-react-parser";
import useTeamMemberQuery from "../../../hooks/use-team-member-query";

const TeamArchive = ({ filter, title, projects }) => {
  const [defaultOption, setDefaultOption] = React.useState(null);
  const [division, setDivision] = React.useState(projects ? 'project' : 'sales');
  const agents = useTeamMemberQuery();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryParams = new URLSearchParams(window.location.search);
      const divisionParam = queryParams.get('division');
      if (divisionParam) {
        setDivision(divisionParam);
        setDefaultOption(divisionParam);
      }
    }
  }, [typeof window !== "undefined" && window.location.search]);

  // Filter agents based on the selected division
  const filteredAgents = agents?.filter(agent => {
    if (division === 'sales') {
      return agent.salesDivision;
    }
    if (division === 'rental') {
      return agent.rentalDivision;
    }
    if (division === 'project') {
      return agent.projectDivision;
    }
    return true;
  });

  return (
    <section className='team-archive'>
      <div className='team-archive__inner'>
        {title && <h2>{parse(title)}</h2>}
        {
          agents?.length > 0 && filter &&
          <div className='team-archive__filter'>
            <form onScroll={e => e.preventDefault()}>
              <div className='form__radio'>
                <input 
                  onChange={() => setDivision('sales')} 
                  name="division" 
                  id='sales-division' 
                  type="radio"
                  checked={division === 'sales'}
                />
                <label htmlFor='sales-division'>Sales Division</label>
              </div>
              <div className='form__radio'>
                <input 
                  onChange={() => setDivision('rental')} 
                  name="division" 
                  id='rental-division' 
                  type="radio" 
                  checked={division === 'rental'}
                />
                <label htmlFor='rental-division'>Rental Division</label>
              </div>
            </form>
          </div>
        }

        <div className='team-archive__items'>
          {filteredAgents?.length > 0 && filteredAgents.map((agent, i) => (<AgentCard key={i} {...agent} />))}
        </div>
      </div>
    </section>
  );
}

export default TeamArchive;

export const query = graphql`
  fragment TeamArchiveProps on DatoCmsTeamArchive {
    ... on DatoCmsTeamArchive {
      id
      title
      filter
      projects
    }
  }
`;