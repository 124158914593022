import React, { useEffect } from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { Asset } from "../../../../plugins/gatsby-plugin-atollon";
import StructuredText from "../custom-structured-text-renderer";

// Import the sal.js library if not already done in the project
import sal from 'sal.js'; 

const Timeline = ({ title, subtitle, secondTitle, secondSubtitle, years }) => {
  
  useEffect(() => {
    // Initialize Scroll Animation Library (sal.js)
    sal();
    
    return () => {
      // Cleanup logic if sal.js supports it
      if (typeof sal === 'function' && sal.reset) {
        sal.reset();
      }
    };
  }, []);

  const renderTimeline = () => (
    <>
      {years?.length > 0 && Array.from({ length: Math.ceil(years.length / 6) }, (_, index) => (
        <div key={index} className="timeline__items">
          {years
            .slice(index * 6, index * 6 + 6)
            .map((item, i) => (
              <div className='timeline__item' key={i} data-sal='slide-up' data-sal-duration='1000'>
                <div className='timeline__image'>
                  {item.image && <Asset asset={item.image} />}
                </div>
                <div className='timeline__content'>
                  <h3>{item.year}</h3>
                  <StructuredText data={item.richContent} />
                </div>
              </div>
            ))}
        </div>
      ))}
    </>
  );

  return (
    <section className='timeline'>
      <div className='timeline__inner'>
        <div className='timeline__title' data-sal='slide-up' data-sal-duration='1000'>
          {subtitle && <span>{parse(subtitle)}</span>}
          {title && <h2>{parse(title)}</h2>}
        </div>

        {renderTimeline()}

        <div className='timeline__title' data-sal='slide-up' data-sal-duration='1000'>
          {secondSubtitle && <span>{parse(secondSubtitle)}</span>}
          {secondTitle && <h2>{parse(secondTitle)}</h2>}
        </div>
      </div>
    </section>
  );
};

export default Timeline;

export const query = graphql`
  fragment TimelineProps on DatoCmsTimeline {
    ... on DatoCmsTimeline {
      id
      title
      subtitle
      secondTitle
      secondSubtitle
      years {
        year
        richContent {
          value
        }
        image {
          ...InternalAssetProps
        }
      }
    }
  }
`;