import { useStaticQuery, graphql } from "gatsby"

const useSuburbQuery = () => {
  const { allDatoCmsSuburb } = useStaticQuery(graphql`
    query Suburb {
      allDatoCmsSuburb(sort: {title: ASC}) {
        nodes {
          title
          postCode
          richHeading {
            value
          }
          richContent {
            value
          }
        }
      }
    }
`)

  return allDatoCmsSuburb.nodes
}

export default useSuburbQuery