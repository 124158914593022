import React from 'react'
import {Asset, Link} from '../../../../plugins/gatsby-plugin-atollon'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import Slider from 'react-slick'

const Service = ({title, items}) => {
  const [current, setCurrent] = React.useState(0)
  const sliderRef = React.useRef(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slickGoTo = (index) => {
    sliderRef.current.slickGoTo(index)
    setCurrent(index)
  }

  return (
    <section className='service'>
        <div className='service__inner'>
          {title && <h2 data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>{parse(title)}</h2>}
          {
            items?.length > 0 &&
            <ul className='service__buttons' data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>
              {
                items.map((item, index) => (
                  <li key={index}>
                    {item.buttonLabel && <button className={index === current ? 'active' : ''} onClick={() => slickGoTo(index)}>{item.buttonLabel}</button>}
                  </li>
                ))
              }
            </ul>
          }

          {
            items?.length > 0 &&
          <div className='service__items' data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>
            <Slider ref={sliderRef} {...settings}>
              {
                items.map((item, index) => (
                  <div key={index} className='service__item'>
                    <div className='service__item--image'>
                      {item.image && <Asset asset={item.image} />}
                    </div>
                    <div className='service__item--content'>
                      {item.title && <h3>{parse(item.title)}</h3>}
                      {item.link?.length > 0 && item.link?.length > 0 &&
                        <Link className='btn btn--border-white' to={item.link[0].link[0].externalLink ? item.link[0].link[0].externalLink : item.link[0].link[0].internalLink.permalink}>
                          {item.link[0].title}
                        </Link>
                      }
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
          }
        </div>
    </section>
  )
}

export default Service

export const query = graphql`
fragment ServiceProps on DatoCmsService {
  ... on DatoCmsService {
    id
    title
    items {
      buttonLabel
      title
      image {
        ...InternalAssetProps
      }
      link {
        ...LinkWithTitleProps
      }
    }
  }
}
`