import React from 'react'
import { Asset, Link } from '../../../../plugins/gatsby-plugin-atollon'
import { graphql } from 'gatsby'
import Stats from "../../property/stats";

const FeatureProperty = ({property}) => {

  const renderProperty = (el) => {
    if (el && el?.length <= 0) return null
    return (
      <div className={`feature-property__item`}>
        {el?.thumbnail && <Link to={el?.permalink} className='feature-property__item--image'><Asset asset={el?.thumbnail}/></Link>}
        <div className='feature-property__item--content'>
          <Link to={el?.permalink} className='feature-property__item--title'>
            {el?.address && <h4>{el.address}</h4>}
            <Stats {...el} />
          </Link>

          <Link to={el?.permalink} className='feature-property__item--price'>
            {el?.price && <p>{el?.price}</p>}
          </Link>

          <div className='feature-property__item--button'>
            {el?.permalink && <Link className='btn btn--border-black-white' to={el?.permalink}>View Listing</Link>}
          </div>
        </div>
      </div>
    )
  }

  return (
    <section className='feature-property'>
      <div className='feature-property__inner'>
        <div className='feature-property__wrapper' data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>
          { property?.address && renderProperty(property)}
        </div>
      </div>
    </section>
  )
}

export default FeatureProperty

export const query = graphql`
fragment FeaturePropertyProps on DatoCmsFeatureProperty {
  ... on DatoCmsFeatureProperty {
    id
    property {
      allBathrooms
      allCarSpaces
      baths
      beds
      carports
      address
      addressComponents {
        streetNumber
        street
        city
        unit
      }
      for
      heading
      images {
        primary {
          ...MediaItemProps
        }
      }
      thumbnail {
        ...InternalAssetProps
      }
      price
      permalink
    }
  }
}
`;