import React from 'react'
import { Asset, MediaItem } from '../../../../plugins/gatsby-plugin-atollon'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

const LandingBanner = ({image, media, title, paddingBottom}) => {

  let videoId = media[0]?.externalVideo?.url.split('v=')[1]
  // could also be a .be youtube link like this https://youtu.be/3pEkcuTxnRY
  if (!videoId) {
    videoId = media[0]?.externalVideo?.url.split('.be/')[1]
  }

  return (
    <section className={`landing-banner ${paddingBottom ? 'landing-banner__padding-bottom' : ''}`}>
      <div className="landing-banner__asset">
        {media?.length > 0 && <iframe width="100%" height="100%" src={`//www.youtube.com/embed/${videoId}?autoplay=1&loop=1&mute=1&rel=0&playlist=${videoId}`} frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen />}
        {image && !media && <Asset className="landing-banner__bg" asset={image}/>}
      </div>
      <div className="landing-banner__inner">
        <div className="landing-banner__wrapper">
          <div className="landing-banner__title" data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>
            {title && <h1>{parse(title)}</h1>}
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingBanner

export const query = graphql`
  fragment LandingBannerProps on DatoCmsLandingBanner {
    ... on DatoCmsLandingBanner {
      id
      title
      image {
        ...InternalAssetProps
      }
      media {
        ...MediaItemProps  
      }
    }
  }
`;