import { useStaticQuery, graphql } from "gatsby"

const useLatestPropertyQuery = () => {
  const { allDatoCmsProperty } = useStaticQuery(graphql`
query LatestProperty {
  allDatoCmsProperty(sort: {marketingLiveDate: ASC}, limit: 1, filter: {for: {eq: "sale"}}) {
    nodes {
      title
      slug
      jsonLd
      market
      marketingLiveDate
      seoMetaTags {
        tags
      }
      permalink
      heading
      description {
        value
      }
      for
      files {
        title
        url
      }
      meta {
        publishedAt
      }
      availableDate(formatString: "DD MMM yyyy")
      agents {
        originalId
        permalink
        name
        phone
        email
        jobTitle
        profileImage {
          ...InternalAssetProps
        }
      }
      address
      addressComponents {
        unit
        streetNumber
        street
        city
        coordinates {
          latitude
          longitude
        }
        postCode
      }
      baths
      beds
      price
      allCarSpaces
      floorArea
      landArea
      waterRates
      petsAllowed
      councilRates
      furnished
      propertyType
      thumbnail {
        ...InternalAssetProps
      }
      files {
        url
        title
      }
      images {
        all {
          ...MediaItemProps
        }
        primary {
          ...MediaItemProps
        }
      }
      videos {
        all {
          ...MediaItemProps
        }
      }
      floorPlan {
        all {
          ...MediaItemProps
        }
      }
      soiFile
      auctionDateTime(formatString: "h:mm A DD MMM yyyy")
      inspections {
        startDateTimeUtc
        endDateTimeUtc
      }
    }
  }
}
`)

  return allDatoCmsProperty.nodes
}

export default useLatestPropertyQuery