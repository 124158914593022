import React from "react";
import { graphql } from "gatsby";
import StructuredText from "../custom-structured-text-renderer";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Asset } from "../../../../plugins/gatsby-plugin-atollon";

gsap.registerPlugin(ScrollTrigger);

const Expander = ({ items }) => {
  const [current, setCurrent] = React.useState(null);
  const expanderRef = React.useRef(null); // Reference for the expander element
  const tlRef = React.useRef(null); // Reference for the GSAP timeline

  React.useEffect(() => {
    if (!items || items.length === 0) return; // Ensure items exist

    const targets = gsap.utils.toArray(".expander__item--content", expanderRef.current);
    if (targets.length === 0) return; // Prevent errors if no elements are found

    const middleTargets = targets.slice();
    const first = middleTargets.shift();
    const last = middleTargets.pop();

    const mm = gsap.matchMedia();

    mm.add(
      {
        isMobile: "(max-width: 900px)",
        isDesktop: "(min-width: 901px)"
      },
      (context) => {
        const { isMobile } = context.conditions;
        const maxHeight = isMobile ? '600px' : '400px';

        gsap.set(first, { maxHeight });

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: expanderRef.current,
            scrub: 1,
            start: "top top",
            end: `bottom top`,
            pin: expanderRef.current,
          }
        });

        tl.to(first, {
          maxHeight: 0,
          duration: 3,
          onUpdate: () => setCurrent(0),
        });

        middleTargets.forEach((el, i) => {
          tl.to(el, {
            maxHeight,
            duration: 3,
            stagger: {
              each: 4,
              yoyo: true,
              repeat: 1,
            },
            onUpdate: () => setCurrent(i + 1),
          });
        });

        tl.to(last, {
          duration: 3,
          maxHeight,
          onUpdate: () => setCurrent(targets.length - 1),
        });

        tlRef.current = tl; // Store timeline reference
      }
    );

    return () => {
      if (mm) mm.revert(); // Revert instead of clear to properly handle media queries
      if (tlRef.current) tlRef.current.kill(); // Ensure the timeline is killed
      ScrollTrigger.getAll().forEach((trigger) => {
        if (trigger && trigger.trigger && document.contains(trigger.trigger)) {
          trigger.kill(); // Only kill triggers if their elements exist
        }
      });
    };
  }, [items]); // Re-run effect when `items` changes

  return (
    <section className="expander" ref={expanderRef}>
      <div className="expander__inner">
        <div className="expander__wrapper">
          <div className="expander__images">
            {items?.length > 0 &&
              items.map((el, i) => (
                <div key={i} className={`expander__image ${current === i ? 'active' : ''}`}>
                  {el.image && <Asset key={i} asset={el.image} />}
                </div>
              ))}
          </div>

          <div className="expander__items">
            {items?.length > 0 &&
              items.map((el, i) => (
                <div key={i} className="expander__item">
                  {el.title && <h3>{el.title}</h3>}
                  {el.richContent && (
                    <div className="expander__item--content">
                      {el.image && <Asset key={i} asset={el.image} />}
                      <StructuredText data={el.richContent} />
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Expander;

export const query = graphql`
  fragment ExpanderProps on DatoCmsExpander {
    ... on DatoCmsExpander {
      id
      items {
        image {
          ...InternalAssetProps
        }
        title
        richContent {
          value
        }
      }
    }
  }
`;
