import React from "react";
import {Asset, Link} from "../../../plugins/gatsby-plugin-atollon";
import {Direct} from "../icons";

const AgentCard = (props) => {
  return (
    <div className='card card--agent'>
      <div className='card--agent__image'>
        <Link to={props.permalink}>{props.profileImage && <Asset asset={props.profileImage}/>}</Link>
      </div>

      <div className='card--agent__info'>
        {(props.title || props.name) && <h4><Link to={props.permalink}>{props.title || props.name}</Link></h4>}
        {props.jobTitle && <p>{props.jobTitle}</p>}
        <div className='card--agent__button'>
          {props.phone && <Link className='btn btn--border-black-white' to={`tel:${props.phone}`}>Call <Direct/></Link>}
          {props.email && <Link className='btn btn--border-black-white' to={`mailto:${props.email}`}>Email <Direct/></Link>}
        </div>
      </div>
    </div>
  )
}

export default AgentCard;