import React from 'react'
import { graphql } from 'gatsby'
import SearchUpcomingComponent from "../../search/search-upcoming";

const Upcoming = ({ title }) => {
  return (
      <SearchUpcomingComponent
        searchType={'property'}
        defaultHeading={title}
        referenceType={'auction'}
      />
  )
}

export default Upcoming

export const query = graphql`
fragment UpcomingProps on DatoCmsUpcoming {
  ... on DatoCmsUpcoming {
    id
    title
  }
}
`