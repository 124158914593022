import React from "react";
import sal from 'sal.js'
import {UseFiltersReturnable, FilterArgs, SortType} from "../../../plugins/gatsby-plugin-atollon/real-src/components/filter";
import { useFilters, Link } from '../../../plugins/gatsby-plugin-atollon'
import { Filter, Search, Close } from '../icons'
import {ResultsLoadTextInfinite} from './results-load-text';
import PropertyCard from '../cards/property'
import AgentCard from '../cards/agent'
import Select from "react-select";
import {useLocation} from "@reach/router";
import * as qs from 'qs'
import * as deepmerge from 'deepmerge'
import {navigate} from "gatsby";
import StructuredText from '../structured-text/custom-structured-text-renderer'
import {FilterConfig} from "./types";
import property from "./configs/property";
import transform from "./transform";
import useSuburbQuery from '../../hooks/use-suburb-query'
import stringHash from "string-hash";
// import { TextLinesReveal } from '../../utils/text-lines-reveal';
import {
    priceMax,
    priceMin,
    bedBathCarOptions,
    propertyStatuses,
    propertyTypeOptions,
    rentPriceMax,
    rentPriceMin,
} from "./constants";
import { build } from "gatsby/dist/utils/webpack/bundle";
import Auction from '../cards/auction'


const customStyles = {
    menuPortal: provided => ({ ...provided, zIndex: 9 }),
    menu: provided => ({ ...provided, zIndex: 9 })
}

const config: FilterConfig = {
    property: property,
}

export const buildSearchQuery = (props: Partial<Omit<FilterArgs, 'sort'>>, pageNumber?: number) => {
    return `?${qs.stringify({
        ...props,
        pageNumber: pageNumber ? pageNumber : undefined,
    }, { arrayFormat: 'brackets'})}`
}

const SearchUpcomingComponent = ({
                             searchType,
                             forType,
                             defaultHeading,
                             referenceType,
                             ...componentProps
                         }: {
    searchType: keyof typeof config,
    forType?: string,
    defaultHeading?: string,
}) => {

    const location = useLocation()
    const { search } = location
    const [parsed, setParsed] = React.useState(qs.parse(search.replace("?", "")))
    const [toggle, setToggle] = React.useState<boolean>(false)
    const [stableDate] = React.useState(new Date().toISOString())
    const [stableDateNonString] = React.useState(new Date());

    const set = config[searchType]

    const {
        sort: _,
        ...initialState
    } =  deepmerge(set.initialState({ componentProps, queryTypeArgs: parsed, location }), parsed || {}, {
        arrayMerge: (destinationArray, sourceArray, options) => sourceArray
    })

    const [currentState, setCurrentState] = React.useState<Omit<FilterArgs, 'sort'>>(initialState)

    const sortOptions = set.sorts({ currentState, componentProps, stableDate, referenceType })

    const [sort, setSort] = React.useState(() => {
        if (parsed.sort) {
            const found = set.sorts({ componentProps, currentState }).find(x => x.label === parsed?.sort)
            if (found) {
                return found
            }
        }
        return sortOptions?.[0]
    })

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    const fields = {
        for: referenceType === 'auction' ? { in: ['sale', 'off-market-sale'] } : { eq: forType }
    };
    
    if (referenceType === 'auction') {
        fields.auctionDateTime = { gte: formattedDate };
    }

    const props = useFilters({
        type: 'property',
        fields: fields,
        sort: sort?.sort,
        referenceFields: sort?.referenceFields,
        perPage: 12
    })

    const {
        results,
        pageNext,
        totalCount,
        hasMore,
        loading,
        setFieldFilter,
    } = props;
    
    React.useEffect(() => {
        sal()
    }, [results])
    

    return (
        <section className={`property-archive`}>
            {defaultHeading &&
              <div className='property-archive__title'>
                <h1>{defaultHeading}</h1>
              </div>
            }

            <div className={`property-archive__filter`}>
                <div className={`search${toggle ? ' active animated' : ''}`}>
                    <form className="search__form form" onSubmit={(e) => {
                        e.preventDefault()
                    }}>
                        <div className="search__row search__row--search">
                            <div className='search__row search__row--box'>
                                <input
                                    className="form__input"
                                    type="text"
                                    name="search"
                                    placeholder="Search"
                                    defaultValue={props?.fields?.title?.matches?.pattern}
                                    onChange={(e) => {
                                        const text = e?.target?.value
                                        if (text) {
                                            props.setFieldFilter({
                                                title: {matches: {pattern: text, caseSensitive: false}}
                                            })
                                        } else {
                                            props.removeFieldFilter("title")
                                        }
                                    }}
                                />
                                <button type='button'>
                                    <Search/>
                                </button>
                            </div>

                            <button type='button' className={`form__toggle--mobile form__toggle${toggle ? ' active' : ''}`} onClick={(e) => setToggle(!toggle)}>
                                {toggle ? 'Close Filters' : 'Filters'} {toggle ? <Close/> : <Filter/>}
                            </button>
                            <div className={`form__select form__select--property-type`}>
                                <Select
                                    options={propertyTypeOptions}
                                    onChange={(option) => {
                                        if (!option || option.value === '') {
                                            props.removeFieldFilter("propertyType")
                                        } else {
                                            props.setFieldFilter({
                                                propertyType: {eq: option.value}
                                            })
                                        }
                                    }}
                                    openMenuOnFocus={true}
                                    id="property-type"
                                    name="property-type"
                                    defaultValue={props?.fields?.propertyType?.eq || ""}
                                    placeholder='Property Type'
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                />
                            </div>
                        </div>
                        <div className={`search__row search__row--dropdowns${toggle ? ' toggle' : ''}`}>
                            <div className={`form__select form__select--price-min`}>
                                <Select
                                    options={(forType === 'lease' || forType === 'leased') ? rentPriceMin : priceMin}
                                    onChange={(option) => {
                                        props.combineFieldFilter({priceSearch: {gte: Number(option.value)}});
                                    }}
                                    openMenuOnFocus={true}
                                    id="price-min"
                                    name="price-min"
                                    defaultValue={props?.fields?.propertyType?.gte || ""}
                                    placeholder='Min Price'
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            <div className={`form__select form__select--price-max`}>
                                <Select
                                    options={(forType === 'lease' || forType === 'leased') ? rentPriceMax : priceMax}
                                    onChange={(option) => {
                                        props.combineFieldFilter({ priceSearch: { lte: Number(option.value) }});
                                    }}
                                    openMenuOnFocus={true}
                                    id="price-max"
                                    name="price-max"
                                    defaultValue={props?.fields?.priceSearch?.lte || ""}
                                    placeholder='Max Price'
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            <div className={`form__select form__select--stats`}>
                                <div className={`form__select form__select--bed`}>
                                    <Select
                                        options={bedBathCarOptions}
                                        onChange={(option) => {
                                            if (Number(option.value) === 0 || !option.value) {
                                                props.removeFieldFilter('beds')
                                                return;
                                            }
                                            props.setFieldFilter({beds: {gte: Number(option.value)}});
                                        }}
                                        openMenuOnFocus={true}
                                        id="beds"
                                        name="beds"
                                        defaultValue={props?.fields?.beds?.get || ""}
                                        placeholder='Beds'
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                                <div className={`form__select form__select--bath`}>
                                    <Select
                                        options={bedBathCarOptions}
                                        onChange={(option) => {
                                            if (Number(option.value) === 0 || !option.value) {
                                                props.removeFieldFilter('baths')
                                                return;
                                            }
                                            props.setFieldFilter({baths: {gte: Number(option.value)}});
                                        }}
                                        openMenuOnFocus={true}
                                        id="baths"
                                        name="baths"
                                        defaultValue={props?.fields?.baths?.get || ""}
                                        placeholder='Baths'
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                                <div className={`form__select form__select--car`}>
                                    <Select
                                        options={bedBathCarOptions}
                                        onChange={(option) => {
                                            if (Number(option.value) === 0 || !option.value) {
                                                props.removeFieldFilter('allCarSpaces')
                                                return;
                                            }
                                            props.setFieldFilter({allCarSpaces: {gte: Number(option.value)}});
                                        }}
                                        openMenuOnFocus={true}
                                        id="cars"
                                        name="cars"
                                        defaultValue={props?.fields?.allCarSpaces?.get || ""}
                                        placeholder='Cars'
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </div>
                            <div className='search__button'>
                                <button
                                    className='form__submit btn btn--border-white'
                                    onClick={() => {
                                        setToggle(false)
                                    }}
                                >
                                    View Properties
                                </button>
                                <button onClick={(e) => {
                                    // navigate(`${option.page}/${buildSearchQuery({for: {eq: option.for}})}`)
                                }} className='form__reset'>
                                    Reset Filters
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className={`upcoming-archive__inner property-archive__inner`}>
            <div className={`form__select form__select--sort`}>
                            {sortOptions?.length > 0 && currentState.type !== 'agent' && (
                                    <Select
                                        options={sortOptions.map((x) => {
                                            return { label: x.label, value: x.label }
                                        })}
                                        onChange={ (value) => {
                                            const foundSort = sortOptions.find(x => x.label === value.label)
                                            setSort(foundSort)
                                        }}
                                        name="sort"
                                        id="sort"
                                        autoFocus={ false }
                                        placeholder="Sort By: Relevance"
                                        isSearchable={ false }
                                        className='react-select-container'
                                        classNamePrefix='react-select'
                                    />
                                )}
                </div>
                <div className={`upcoming-archive__list`}>
                    {results?.map((x, i) => {
                        if (x.data) {
                            const stableDateUnix = stableDateNonString ? new Date(stableDateNonString.getTime()).getTime() : 0;

                            // Filter inspections
                            let inspections = x.data?.inspections?.filter(
                                (inspection) => new Date(inspection.startDateTimeUtc).getTime() > stableDateUnix
                            );

                            if (inspections?.length > 0) {
                                return (
                                    <Auction
                                        isInspection={Boolean(forType)}
                                        inspectionCutoffDate={stableDate}
                                        {...x.data}
                                        key={i}
                                    />
                                );
                            }
                        }
                        return null;
                    })}
                </div>
                <div className={`property-archive__load`}>
                    <ResultsLoadTextInfinite results={results} hasMore={hasMore} pageNext={pageNext} loading={loading} totalCount={totalCount}/>
                </div>
            </div>
        </section>
    )
}


export default SearchUpcomingComponent