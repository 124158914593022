import React from "react";
import { useInView } from "react-intersection-observer"
import { Loading } from "../icons"


const ResultsLoadText = ({ results, totalCount, pageNext, loading, hasMore }) => {

    const { ref: inViewRef, inView } = useInView({
        initialInView: true,
    })

    React.useEffect(() => {
        if (inView && !loading && hasMore) {
            pageNext()
        }
    }, [inView, loading, hasMore])

    if (totalCount === "*") {
        return (
            <div className='infinite'>
                {loading && <div className='infinite__loading'><Loading /></div> }
                {hasMore && <Loading />}
                <div ref={inViewRef} style={{ position: 'absolute', zIndex: 100, height: '40px', bottom: '150vh', width: '100%', pointerEvents: 'none', }}  />
            </div>
        )
    }
    return (
        <div className='infinite'>
            {loading && <div className='infinite__loading'><Loading /></div> }
            {hasMore && <Loading />}
            {(!loading && results.length === 0) && <h6 className='no-results'>No results match your search criteria.</h6>}
        </div>
    )
}


export const ResultsLoadTextInfinite = ({ results, totalCount, pageNext, loading, hasMore }) => {
    const { ref: inViewRef, inView } = useInView({
        initialInView: true,
    })

    React.useEffect(() => {
        if (inView && !loading && hasMore) {
            pageNext()
        }
    }, [inView, loading, hasMore])

    return (
        <>
            <ResultsLoadText results={results} totalCount={totalCount} pageNext={pageNext} loading={loading} hasMore={hasMore} />
            <div ref={inViewRef} style={{ position: 'absolute', zIndex: 100, height: '40px', bottom: '150vh', width: '100%', pointerEvents: 'none', }}  />
        </>
    )
}

export default ResultsLoadText
