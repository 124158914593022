import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Asset } from '../../../../plugins/gatsby-plugin-atollon';
import StructuredText from "../custom-structured-text-renderer";
import parse from 'html-react-parser';
import sal from 'sal.js'; // Import sal.js if not globally imported

const AboutBanner = ({ image, mobileImage, video, title, richContent }) => {

  useEffect(() => {
    // Initialize SAL (Scroll Animation Library)
    sal();

    return () => {
      // Cleanup SAL when navigating away
      if (typeof sal === 'function' && sal.reset) {
        sal.reset(); // This ensures any animations are cleaned up
      }
    };
  }, []);

  return (
    <section className='about-banner'>
      <div className='about-banner__asset'>
        {video && (
          <video
            className='about-banner__video'
            poster={image?.url}
            src={video}
            autoPlay
            loop
            muted
            playsInline
          />
        )}
        {image && <Asset className='about-banner__bg about-banner__bg--desktop' asset={image} />}
        {(mobileImage || image) && <Asset className={`about-banner__bg about-banner__bg--mobile${mobileImage ? ' has-mobile-image' : ''}`} asset={mobileImage ? mobileImage : image} />}
      </div>
      <div className='about-banner__inner'>
        <div className="about-banner__title" data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>
          {title && <h1>{parse(title)}</h1>}
          {richContent && <StructuredText data={richContent} />}
        </div>
      </div>
    </section>
  );
}

export default AboutBanner;

export const query = graphql`
  fragment AboutBannerProps on DatoCmsAboutBanner {
    ... on DatoCmsAboutBanner {
      id
      image {
        ...InternalAssetProps
      }
      mobileImage {
        ...InternalAssetProps
      }
      video
      title
      richContent {
        value
      }
    }
  }
`;
