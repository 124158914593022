import React from "react";
import {Link} from "../../plugins/gatsby-plugin-atollon";
import {Logo, Instagram, LinkedIn, Facebook, Youtube} from "./icons";

const Footer = () => {
  const footerRef = React.useRef(null);

  React.useEffect(() => {
    const handleScroll = () => {
      if (footerRef.current) {
        const footer = footerRef.current;
        const footerTop = footer.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        const halfwayPoint = windowHeight / 2;

        if (footerTop < halfwayPoint) {
          document.body.classList.add('bg--purple');
        } else {
          document.body.classList.remove('bg--purple');
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.classList.remove('bg--purple');
    };
  }, []);

  return (
    <footer className={`footer`} ref={footerRef}>
      <div className='footer__inner'>
        <div className='footer__top'>
          <div className='footer__top--content'>
            <span>Real Equity Estate Agents</span>
            <h2>Make it worth it.</h2>
          </div>

          <div className='footer__top--links'>
            <h3>Quicklinks</h3>
            <div className='footer__top--nav'>
              <ul>
                <li><Link to='/buy/'>Buy</Link></li>
                <li><Link to='/sold/'>Sell</Link></li>
                <li><Link to='/lease/'>Lease</Link></li>
                <li><Link to='/team/'>Team</Link></li>
                <li><Link to='/about/'>About</Link></li>
                <li><Link to='/contact/'>Contact</Link></li>
              </ul>
              <ul>
                <li><Link to='https://www.instagram.com/Realequity' target='_blank' rel='noopener noreferrer'>Instagram</Link></li>
                <li><Link to='https://www.facebook.com/RealEquity/' target='_blank' rel='noopener noreferrer'>Facebook</Link></li>
                <li><Link to='https://www.linkedin.com/company/real-equity---estate-agents' target='_blank' rel='noopener noreferrer'>Linkedin</Link></li>
                <li><Link to='https://www.youtube.com/channel/UCeajcCUXhCEHe3J42smjZzA' target='_blank' rel='noopener noreferrer'>YouTube</Link></li>
              </ul>
            </div>
          </div>
        </div>

        <div className='footer__bottom'>
          <Link className='logo' to='/'>
            <Logo/>
          </Link>
          <div className='footer__bottom--content'>
            <ul>
              <li><Link to='https://www.instagram.com/Realequity' target='_blank' rel='noopener noreferrer'><Instagram/></Link></li>
              <li><Link to='https://www.facebook.com/RealEquity/' target='_blank' rel='noopener noreferrer'><Facebook/></Link></li>
              <li><Link to='https://www.youtube.com/channel/UCeajcCUXhCEHe3J42smjZzA' target='_blank' rel='noopener noreferrer'><Youtube/></Link></li>
            </ul>
            <div className='footer__bottom--reserved'>
              <p>All rights reserved Real Equity {new Date().getFullYear()}.</p>
              <Link to='https://atollon.com.au?utm_source=realequity&utm_medium=website&utm_campaign=referrer&utm_term=realestate' target='_blank' rel='noopener noreferrer'>Design by Atollon</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer