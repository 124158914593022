import React from "react";
import sal from 'sal.js'
import {UseFiltersReturnable, FilterArgs, SortType} from "../../../plugins/gatsby-plugin-atollon/real-src/components/filter";
import { useFilters, Link } from '../../../plugins/gatsby-plugin-atollon'
import { Filter, Search, Close } from '../icons'
import {ResultsLoadTextInfinite} from './results-load-text';
import PropertyCard from '../cards/property'
import AgentCard from '../cards/agent'
import Select from "react-select";
import {useLocation} from "@reach/router";
import * as qs from 'qs'
import * as deepmerge from 'deepmerge'
import {navigate} from "gatsby";
import StructuredText from '../structured-text/custom-structured-text-renderer'
import {FilterConfig} from "./types";
import property from "./configs/property";
import transform from "./transform";
import useSuburbQuery from '../../hooks/use-suburb-query'
import stringHash from "string-hash";
// import { TextLinesReveal } from '../../utils/text-lines-reveal';
import {
    priceMax,
    priceMin,
    bedBathCarOptions,
    propertyStatuses,
    projectStatuses,
    propertyTypeOptions,
    rentPriceMax,
    rentPriceMin,
} from "./constants";
import { build } from "gatsby/dist/utils/webpack/bundle";


const customStyles = {
    menuPortal: provided => ({ ...provided, zIndex: 9 }),
    menu: provided => ({ ...provided, zIndex: 9 })
}

const config: FilterConfig = {
    property: property,
}

export const buildSearchQuery = (props: Partial<Omit<FilterArgs, 'sort'>>, pageNumber?: number) => {
    return `?${qs.stringify({
        ...props,
        pageNumber: pageNumber ? pageNumber : undefined,
    }, { arrayFormat: 'brackets'})}`
}

const SearchComponent = ({
    searchType,
    forType,
    defaultHeading,
    ...componentProps
}: {
searchType: keyof typeof config,
forType?: string,
defaultHeading?: string,
}) => {
    const location = useLocation()
    const { search } = location
    const [parsed, setParsed] = React.useState(qs.parse(search.replace("?", "")))
    const [toggle, setToggle] = React.useState<boolean>(false)
    const [stableDate] = React.useState(new Date().toISOString())

    // Cannot be changed internally
    if (searchType === 'project') {
        searchType = 'property'
        parsed.type = 'project'
    }

    const set = config[searchType]

    const {
        sort: _,
        ...initialState
    } =  deepmerge(set?.initialState({ componentProps, queryTypeArgs: parsed, location }), parsed || {}, {
        arrayMerge: (destinationArray, sourceArray, options) => sourceArray
    })

    const [currentState, setCurrentState] = React.useState<Omit<FilterArgs, 'sort'>>(initialState)


    const suburbs = useSuburbQuery()
    let suburbOptions = suburbs?.map((suburb) => {
        return {
            label: suburb.title,
            value: suburb.title
        }
    })
    suburbOptions.unshift({ label: "Select", value: ''})

    
    const sortOptions = set.sorts({ componentProps, currentState, stableDate })


    const [sort, setSort] = React.useState(() => {
        if (parsed.sort) {
            const found = set.sorts({ componentProps, currentState }).find(x => x.label === parsed?.sort)
            if (found) {
                return found
            }
        }
        return sortOptions?.[0]
    })

    const props = useFilters({
        ...currentState,
        sort: sort?.sort,
        referenceFields: sort?.referenceFields,
    })

    const {
        error,
        results,
        loading,
        currentCount,
        totalCount,
        pageNext,
        hasMore,
        setAll,
    } = props;

    React.useEffect(() => {
        sal()
        window.history.pushState(null, null, `${window.location.pathname}${buildSearchQuery(props.fields)}`)
    }, [results])

    return (
        <section className={`property-archive`}>
            {defaultHeading &&
            <div className='property-archive__title'>
              <h1>{defaultHeading}</h1>
            </div>
            }

            <div className={`property-archive__filter`}>
                <div className={`search${toggle ? ' active animated' : ''}`}>
                    <form className="search__form form" onSubmit={(e) => {
                        e.preventDefault()
                    }}>
                        <div className="search__row search__row--search">
                            <div className='search__row search__row--box'>
                                <input
                                    className="form__input"
                                    type="text"
                                    name="search"
                                    placeholder="Search"
                                    defaultValue={props?.fields?.title?.matches?.pattern}
                                    onChange={(e) => {
                                        const text = e?.target?.value
                                        if (text) {
                                            props.setFieldFilter({
                                                title: {matches: {pattern: text, caseSensitive: false}}
                                            })
                                        } else {
                                            props.removeFieldFilter("title")
                                        }
                                    }}
                                />
                                <button type='button'>
                                    <Search/>
                                </button>
                            </div>

                            <button type='button' className={`form__toggle--mobile form__toggle${toggle ? ' active' : ''}`} onClick={(e) => setToggle(!toggle)}>
                                {toggle ? 'Close Filters' : 'Filters'} {toggle ? <Close/> : <Filter/>}
                            </button>

                            <div className={`form__select form__select--forType`}>
                                <Select
                                    options={parsed.type === 'project' ? projectStatuses : propertyStatuses}
                                    onChange={(option) => {
                                        if (parsed.type === 'project') {
                                            if (option.value === '') {
                                                props.removeFieldFilter("for")
                                            } else {
                                                props.setFieldFilter({
                                                    for: {in: [option.value]}
                                                })
                                            }
                                        } else {
                                            navigate(`${option.page}/${buildSearchQuery({for: {eq: option.for}})}`)
                                        }
                                    }}
                                    openMenuOnFocus={true}
                                    id="for"
                                    name="for"
                                    defaultValue={props?.fields?.forType?.eq}
                                    placeholder={parsed.type === 'project' ? 'Project Status' : propertyStatuses.find(x => x.for === props?.fields?.for?.eq)?.label}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            { parsed.type !== 'project' &&
                                <div className={`form__select form__select--property-type`}>
                                    <Select
                                        options={propertyTypeOptions}
                                        onChange={(option) => {
                                            if (!option || option.value === '') {
                                                props.removeFieldFilter("propertyType")
                                            } else {
                                                props.setFieldFilter({
                                                    propertyType: {eq: option.value}
                                                })
                                            }
                                        }}
                                        openMenuOnFocus={true}
                                        id="property-type"
                                        name="property-type"
                                        defaultValue={props?.fields?.propertyType?.eq || ""}
                                        placeholder='Property Type'
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            }
                        </div>
                        <div className={`search__row search__row--dropdowns${toggle ? ' toggle' : ''}`}>
                            <div className={`form__select form__select--price-min`}>
                                <Select
                                    options={(forType === 'lease' || forType === 'leased') ? rentPriceMin : priceMin}
                                    onChange={(option) => {
                                        props.combineFieldFilter({priceSearch: {gte: Number(option.value)}});
                                    }}
                                    openMenuOnFocus={true}
                                    id="price-min"
                                    name="price-min"
                                    defaultValue={props?.fields?.propertyType?.gte || ""}
                                    placeholder='Min Price'
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            <div className={`form__select form__select--price-max`}>
                                <Select
                                    options={(forType === 'lease' || forType === 'leased') ? rentPriceMax : priceMax}
                                    onChange={(option) => {
                                        props.combineFieldFilter({priceSearch: {lte: Number(option.value)}});
                                    }}
                                    openMenuOnFocus={true}
                                    id="price-max"
                                    name="price-max"
                                    defaultValue={props?.fields?.priceSearch?.lte || ""}
                                    placeholder='Max Price'
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            { parsed.type !== 'project' &&
                                <div className={`form__select form__select--stats`}>
                                    <div className={`form__select form__select--bed`}>
                                        <Select
                                            options={bedBathCarOptions}
                                            onChange={(option) => {
                                                if (Number(option.value) === 0 || !option.value) {
                                                    props.removeFieldFilter('beds')
                                                    return;
                                                }
                                                props.setFieldFilter({beds: {gte: Number(option.value)}});
                                            }}
                                            openMenuOnFocus={true}
                                            id="beds"
                                            name="beds"
                                            defaultValue={props?.fields?.beds?.get || ""}
                                            placeholder='Beds'
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                        />
                                    </div>
                                    <div className={`form__select form__select--bath`}>
                                        <Select
                                            options={bedBathCarOptions}
                                            onChange={(option) => {
                                                if (Number(option.value) === 0 || !option.value) {
                                                    props.removeFieldFilter('baths')
                                                    return;
                                                }
                                                props.setFieldFilter({baths: {gte: Number(option.value)}});
                                            }}
                                            openMenuOnFocus={true}
                                            id="baths"
                                            name="baths"
                                            defaultValue={props?.fields?.baths?.get || ""}
                                            placeholder='Baths'
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                        />
                                    </div>
                                    <div className={`form__select form__select--car`}>
                                        <Select
                                            options={bedBathCarOptions}
                                            onChange={(option) => {
                                                if (Number(option.value) === 0 || !option.value) {
                                                    props.removeFieldFilter('allCarSpaces')
                                                    return;
                                                }
                                                props.setFieldFilter({allCarSpaces: {gte: Number(option.value)}});
                                            }}
                                            openMenuOnFocus={true}
                                            id="cars"
                                            name="cars"
                                            defaultValue={props?.fields?.allCarSpaces?.get || ""}
                                            placeholder='Cars'
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                        />
                                    </div>
                                </div>
                            }
                            <div className='search__button'>
                                <button
                                    className='form__submit btn btn--border-white'
                                    onClick={() => {
                                        setToggle(false)
                                    }}
                                >
                                    View Properties
                                </button>
                                <button onClick={(e) => {
                                    // navigate(`${option.page}/${buildSearchQuery({for: {eq: option.for}})}`)
                                }} className='form__reset'>
                                    Reset Filters
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className={`property-archive__inner`}>
                <div className={`form__select form__select--sort`}>
                            {sortOptions?.length > 0 && currentState.type !== 'agent' && (
                                    <Select
                                        options={sortOptions.map((x) => {
                                            return { label: x.label, value: x.label }
                                        })}
                                        onChange={ (value) => {
                                            const foundSort = sortOptions.find(x => x.label === value.label)
                                            setSort(foundSort)
                                        }}
                                        name="sort"
                                        id="sort"
                                        autoFocus={ false }
                                        placeholder="Sort By: Relevance"
                                        isSearchable={ false }
                                        // value={{ label: sort.label, value: sort.label }}
                                        className='react-select-container'
                                        classNamePrefix='react-select'
                                    />
                                )}
                </div>
                <div className={`property-archive__list`}>
                    {results?.map((x, i) => {
                        if (x.data) {
                            return (<PropertyCard key={i} {...x.data} />)
                        }
                        return null
                    })}
                </div>
                <div className={`property-archive__load`}>
                    <ResultsLoadTextInfinite results={results} hasMore={hasMore} pageNext={pageNext} loading={loading} totalCount={totalCount}/>
                </div>
            </div>
        </section>
    )
}


export default SearchComponent