import { useStaticQuery, graphql } from "gatsby"

const useTeamMemberQuery = () => {
  const { allDatoCmsAgent } = useStaticQuery(graphql`
query Agents {
  allDatoCmsAgent(filter: {hideForLocale: {ne: true}}, sort: {position: ASC}) {
    nodes {
      id
      originalId
      title
      jobTitle
      permalink
      role
      biography {
        value
      }
      hideForLocale
      profileImage {
        ...InternalAssetProps
      }
      phone
      email
      salesDivision
      rentalDivision
      projectDivision
    }
  }
}
`)

  return allDatoCmsAgent.nodes
}

export default useTeamMemberQuery