import { FilterSelectOption } from "./types";

const bedBathCarOptions: FilterSelectOption[] = [
    { label: "Any", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
]

const propertyStatuses = [
    {
        label: "Buy",
        for: "sale",
        page: "/buy"
    },
    {
        label: "Sold",
        for: "sold",
        page: "/sold"
    },
    {
        label: "Lease",
        for: "lease",
        page: "/lease"
    },
    {
        label: "Leased",
        for: "leased",
        page: "/leased"
    },
]

const projectStatuses = [
    {label: "Current Projects", value: ''},
    {label: "Now Selling", value: 'now-selling'},
    {label: "Construction Underway", value: 'construction-underway'},
    {label: "Past Projects", value: 'past-projects'},
]

const secondaryFilters = [
    {
        label: "Min Price",
        name: "price-min",
    },
    {
        label: "Max Price",
        name: "price-max",
    },
    {
        label: "Bed",
        name: "beds",
    },
    {
        label: "Bath",
        name: "baths",
    },
    {
        label: "Car",
        name: "allCarSpaces",
    }
]

const propertyTypeOptions: FilterSelectOption[] = [
    { label: 'Property Type', value: '' },
    { label: "Apartment", value: 'apartment' },
    { label: "House", value: 'house' },
    { label: "Studio", value: 'Studio' },
    { label: "Townhouse", value: 'townhouse' },
    { label: "Unit", value: 'unit' },
    { label: "Vacant Land (Residential)", value: 'land' },
]

const rentPriceMin: FilterSelectOption[] = [
    { label: "<$300", value: '0' },
    { label: "$300", value: '300' },
    { label: "$350", value: '350' },
    { label: "$400", value: '400' },
    { label: "$450", value: '450' },
    { label: "$500", value: '500' },
    { label: "$550", value: '550' },
    { label: "$600", value: '600' },
    { label: "$700", value: '700' },
    { label: "$800", value: '800' },
    { label: "$900", value: '900' },
    { label: "$1k", value: '1000' },
    { label: "$1.1k", value: '1100' },
    { label: "$1.2k", value: '1200' },
    { label: "$1.3k", value: '1300' },
    { label: "$1.4k", value: '1400' },
    { label: "$1.5k", value: '1500' },
]


const rentPriceMax: FilterSelectOption[] = [
    { label: "$300/wk", value: '300' },
    { label: "$350/wk", value: '350' },
    { label: "$400/wk", value: '400' },
    { label: "$450/wk", value: '450' },
    { label: "$500/wk", value: '500' },
    { label: "$550/wk", value: '550' },
    { label: "$600/wk", value: '600' },
    { label: "$700/wk", value: '700' },
    { label: "$800/wk", value: '800' },
    { label: "$900/wk", value: '900' },
    { label: "$1K/wk", value: '1000' },
    { label: "$1.1K/wk", value: '1100' },
    { label: "$1.2K/wk", value: '1200' },
    { label: "$1.3K/wk", value: '1300' },
    { label: "$1.4K/wk", value: '1400' },
    { label: "$1.5K/wk", value: '1500' },
    { label: "$1.5K/wk+", value: '100000' },
]

const priceMin: FilterSelectOption[] = [
    { label: "<$450k", value: '0' },
    { label: "$450k", value: '450000'},
    { label: "$500k", value: '500000'},
    { label: "$600k", value: '600000'},
    { label: "$700k", value: '700000'},
    { label: "$800k", value: '800000'},
    { label: "$900k", value: '900000'},
    { label: "$1m", value: '1000000'},
    { label: "$1.1m", value: '1100000'},
    { label: "$1.2m", value: '1200000'},
    { label: "$1.3m", value: '1300000'},
    { label: "$1.4m", value: '1400000'},
    { label: "$1.5m", value: '1500000'},
    { label: "$1.6m", value: '1600000'},
    { label: "$1.7m", value: '1700000'},
    { label: "$1.8m", value: '1800000'},
    { label: "$1.9m", value: '1900000'},
    { label: "$2m", value: '2000000'},
    { label: "$2.25m", value: '2250000'},
    { label: "$2.5m", value: '2500000'},
    { label: "$2.75m", value: '2750000'},
    { label: "$3m", value: '3000000'},
]

const priceMax: FilterSelectOption[] = [
    { label: "$450k", value: '450000'},
    { label: "$500k", value: '500000'},
    { label: "$600k", value: '600000'},
    { label: "$700k", value: '700000'},
    { label: "$800k", value: '800000'},
    { label: "$900k", value: '900000'},
    { label: "$1m", value: '1000000'},
    { label: "$1.1m", value: '1100000'},
    { label: "$1.2m", value: '1200000'},
    { label: "$1.3m", value: '1300000'},
    { label: "$1.4m", value: '1400000'},
    { label: "$1.5m", value: '1500000'},
    { label: "$1.6m", value: '1600000'},
    { label: "$1.7m", value: '1700000'},
    { label: "$1.8m", value: '1800000'},
    { label: "$1.9m", value: '1900000'},
    { label: "$2m", value: '2000000'},
    { label: "$2.25m", value: '2250000'},
    { label: "$2.5m", value: '2500000'},
    { label: "$2.75m", value: '2750000'},
    { label: "$3m", value: '3000000'},
    { label: "$3.25m+", value: '6000000'},
]

export {
    bedBathCarOptions,
    priceMin,
    priceMax,
    rentPriceMin,
    rentPriceMax,
    propertyTypeOptions,
    propertyStatuses,
    projectStatuses,
    secondaryFilters,
}
