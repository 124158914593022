import React from "react";
import { Link } from "../../../../plugins/gatsby-plugin-atollon";
import { graphql } from "gatsby";
import parse from "html-react-parser";

const ExpertCta = ({title, backgroundColor, subtitle, link}) => {

  return (
    <section className='expert-cta'>
      <div className="expert-cta__inner">
         <div className={`expert-cta__wrapper expert-cta--${backgroundColor === 'Dark Teal' ? 'dark-teal' : 'swirl'}`} data-sal='slide-up' data-sal-duration='1000' data-sal-easing='ease'>
         <div className="expert-cta__content">
            {subtitle && <span>{parse(subtitle)}</span>}   
            {title && <h2>{parse(title)}</h2>}
         </div>
         <div className="expert-cta__button">
         {link.length > 0 &&
            <Link className='btn btn--border-white' to={link[0].link[0].externalLink ? link[0].link[0].externalLink : link[0].link[0].internalLink.permalink}>
              {link[0].title}
            </Link>}   
         </div>
         </div>
      </div>
    </section>
  );
};

export default ExpertCta;

export const query = graphql`
  fragment ExpertCtaProps on DatoCmsExpertCta {
    ... on DatoCmsExpertCta {
      id
      title
      subtitle
      backgroundColor
      link {
        ...LinkWithTitleProps
      }
    }
  }
`;
