import React from 'react';
import {graphql} from "gatsby";
import parse from "html-react-parser";
import StructuredText from "../custom-structured-text-renderer";
import {Plus, Minus} from "../../icons";
import {Link} from "../../../../plugins/gatsby-plugin-atollon";

const TitleContentExpander = ({ title, items, richContent, link }) => {
  const [current, setCurrent] = React.useState(0);

  return (
    <section className="title-content-expander">
      <div className='title-content-expander__inner'>
        {title && <h2>{parse(title)}</h2>}
        {richContent && <div className='title-content-expander__content'><StructuredText  data={richContent} /></div>}

        <div className='title-content-expander__items'>
          {
            items?.length > 0 && items.map((el, i) => (
              <div key={i} className='title-content-expander__item' onClick={() => setCurrent(i)}>
                <div className='title-content-expander__item--title'>
                  {(el.title || el.feature) && <h3>{parse(el.title || el.feature)}</h3>}
                  {current === i ? <Minus/> : <Plus/>}
                </div>
                <div className={`title-content-expander__item--content ${current === i ? 'active' : ''}`}>
                  {el.richContent && <StructuredText data={el.richContent} />}
                </div>
              </div>
            ))
          }
        </div>

        <div className='title-content-expander__button'>
          {link?.length > 0 &&
            <Link className='btn btn--border-white' to={link[0].link[0].externalLink ? link[0].link[0].externalLink : link[0].link[0].internalLink.permalink}>
              {link[0].title}
            </Link>}
        </div>
      </div>
    </section>
  )
}

export default TitleContentExpander

export const query = graphql`
fragment TitleContentExpanderProps on DatoCmsTitleContentExpander {
  ... on DatoCmsTitleContentExpander {
    id
    title
    richContent {
      value
    }
    items {
      title
      richContent {
        value
      }
    }
    link {
      ...LinkWithTitleProps
    }
  }
}
`;